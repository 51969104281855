// @ts-strict-ignore
import { Component, Input } from '@angular/core';

@Component({
    selector: 'k5-menu-item-base',
    templateUrl: './menu-item-base.html',
    styleUrls: ['./menu-item-base.scss']
})
export class MenuItemBaseComponent {
    @Input()
    description: string;

    @Input()
    loading: boolean = false;

    @Input()
    disabled: boolean = false;

    @Input()
    tooltip: string = '';

    /**
     * Verhindert das Click-Event, wenn das Item disabled ist
     * @param event Event
     */
    handleMenuItemClick(event: Event): void {
        if (this.disabled || this.loading) {
            event.stopPropagation();
        }
    }
}
