import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { DetailsucheDto } from '../models/request/detailsucheDto';
import { EinwohnerResultDto } from '../models/response/einwohnerResultDto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EinwohnerDetailDto } from '../models/response/einwohnerDetailDto';

@Injectable({
    providedIn: 'root'
})
export class EinwohnerService {
    private readonly baseUrl: string;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService
    ) {
        this.baseUrl = this.configService.getConfig().k5Meldewesen.meldewesenUiApiUrl;
    }

    /**
     * Liefert die Daten eines Einwohners für die Detailansicht
     * @param mandantId Id des Mandanten
     * @param einwohnerId Id des Einwohners
     * @returns Daten eines Einwohners für die Detailansicht
     */
    getEinwohnerDetail(mandantId: string, einwohnerId: string): Observable<EinwohnerDetailDto> {
        return this.httpClient.get<EinwohnerDetailDto>(
            `${this.baseUrl}/v1/mandanten/${mandantId}/einwohner/${einwohnerId}`
        );
    }

    /**
     * Liefert die Einwohner eines Mandanten für die Anzeige im Grid
     * @param mandantId Id des Mandanten
     * @param request Daten zur Detailsuche
     * @returns Paginated Ergebnis einer Detailsuche
     */
    getEinwohnerTableData(mandantId: string, request: DetailsucheDto): Observable<EinwohnerResultDto> {
        return this.httpClient.post<EinwohnerResultDto>(`${this.baseUrl}/v1/mandanten/${mandantId}/einwohner`, request);
    }
}
