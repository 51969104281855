<div class="flex size-full">
    <div class="flex h-full flex-grow flex-col overflow-auto">
        <k5-responsive-title>
            <k5-page-header
                header="Meldewesen - Suche"
                icon="fa-duotone fa-user-magnifying-glass fg-primary"
                class="pl-4"
            >
            </k5-page-header>
        </k5-responsive-title>

        <k5-section-header [title]="'Einwohner'" icon="fa-people-roof" class="px-lg">
            <div class="flex items-center justify-between">
                <!-- Anzahl der angezeigten, ausgewählten und aller Meldungen -->
                <div class="pl-4 text-sm">
                    {{ tableStateService.loadedRows() }} angezeigte Einträge, {{ tableStateService.selectedRows() }} /
                    {{ tableStateService.totalRows() }} Einträge ausgewählt
                </div>

                <!-- Aktionsmenü -->
                <div class="flex">
                    <!-- Suchergebnis teilen -->
                    <button
                        id="einwohner-suchergebnis-teilen-button"
                        aria-label="Suchergebnis teilen"
                        type="button"
                        mat-icon-button
                        matTooltip="Suchergebnis teilen"
                        cdkOverlayOrigin
                        #overlayTeilenOrigin="cdkOverlayOrigin"
                        data-cy="einwohner-suchergebnis-teilen-button"
                    >
                        <mat-icon
                            fontSet="fa-duotone"
                            fontIcon="fa-share-nodes"
                            class="text-base text-gray-500"
                        ></mat-icon>
                    </button>
                </div>
            </div>
        </k5-section-header>

        <!-- Overlay Suchergebnis teilen -->
        <k5-menu [overlayOrigin]="overlayTeilenOrigin">
            <k5-menu-group heading="aktuelle Auswahl">
                <k5-menu-item
                    icon="fa-file-lines"
                    description="Druck/PDF"
                    (keydown.enter)="navigateToStandardbericht()"
                    (click)="navigateToStandardbericht()"
                    data-cy="einwohner-menu-item-teilen-pdf"
                >
                </k5-menu-item>
                <k5-menu-item
                    icon="fa-file-xmark"
                    description="Excel"
                    [disabled]="false"
                    [loading]="false"
                    (keydown.enter)="downloadExcel()"
                    (click)="downloadExcel()"
                    data-cy="einwohner-menu-item-teilen-excel"
                >
                </k5-menu-item>
                <k5-menu-item
                    icon="fa-file-lines"
                    description="Meldebestätigung"
                    [disabled]="false"
                    [loading]="false"
                    (keydown.enter)="navigateToMeldebestaetigung()"
                    (click)="navigateToMeldebestaetigung()"
                    data-cy="einwohner-menu-item-teilen-meldebestaetigung"
                >
                </k5-menu-item>
            </k5-menu-group>
        </k5-menu>

        <k5-einwohner-table class="flex-grow overflow-auto px-6 pb-6"></k5-einwohner-table>
    </div>

    <!-- Detailsuche -->
    @if (detailSearchOpen()) {
        <aside class="search-panel-container h-full w-80 flex-shrink-0">
            <k5next-search-panel
                titel="Einwohner suchen"
                [showPanelCloseButton]="true"
                [showGespeicherteSuchen]="false"
                [gespeicherteSuchen]="[]"
                [additionalSuchkriterien]="[]"
                [selectedGespeicherteSuche]="null"
                (closePanel)="hideDetailsearch()"
                (resetSuche)="resetSuche()"
                (shareGespeicherteSuche)="(null)"
                (deleteGespeicherteSuche)="(null)"
                (tooltipLinkClick)="(null)"
                (changeAdditionalSuchkriterium)="(null)"
                (saveSuche)="(null)"
                (executeSuche)="executeSuche()"
                (executeGespeicherteSuche)="(null)"
            >
                <div k5next-detailsuche-content>
                    <form
                        id="einwohner-detail-search-form"
                        aria-label="Detailsuche"
                        aria-hidden="true"
                        class="p-4"
                        novalidate
                        [formGroup]="detailSearchForm"
                        (keydown.enter)="(null)"
                    >
                        <!-- Suchkriterien Wohnsitz -->
                        <h3 class="text-xl">Optionen</h3>

                        <mat-slide-toggle formControlName="enableZmrSearch" class="my-3">ZMR-Suche</mat-slide-toggle>

                        <!-- Suchkriterien Einwohner -->
                        <h3 class="text-xl">Person</h3>

                        <!-- Suchkriterium ZMR Zahl -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-zmr-zahl-label">ZMR Zahl</mat-label>
                            <input
                                id="einwohner-detail-search-zmr-zahl-input"
                                matInput
                                type="text"
                                formControlName="zmrZahl"
                                autocomplete="off"
                                aria-labelledby="einwohner-detail-search-zmr-zahl-label"
                                data-cy="einwohner-detail-search-zmr-zahl-input"
                            />
                            @if (detailSearchForm.controls.zmrZahl.value) {
                                <button
                                    tabindex="-1"
                                    type="button"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="detailSearchForm.controls.zmrZahl.reset()"
                                >
                                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                                </button>
                            }
                        </mat-form-field>

                        <!-- Suchkriterium Bpk-ZP -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-bpkzp-label">Bpk-ZP</mat-label>
                            <input
                                id="einwohner-detail-search-bpkzp-input"
                                matInput
                                type="text"
                                formControlName="bpkzp"
                                autocomplete="off"
                                aria-labelledby="einwohner-detail-search-bpkzp-label"
                                data-cy="einwohner-detail-search-bpkzp-input"
                            />
                            @if (detailSearchForm.controls.bpkzp.value) {
                                <button
                                    tabindex="-1"
                                    type="button"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="detailSearchForm.controls.bpkzp.reset()"
                                >
                                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                                </button>
                            }
                        </mat-form-field>

                        <!-- Suchkriterium Name -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-name-label">Name</mat-label>
                            <input
                                id="einwohner-detail-search-name-input"
                                matInput
                                type="text"
                                formControlName="name"
                                autocomplete="off"
                                aria-labelledby="einwohner-detail-search-name-label"
                                data-cy="einwohner-detail-search-name-input"
                            />
                            @if (detailSearchForm.controls.name.value) {
                                <button
                                    tabindex="-1"
                                    type="button"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="detailSearchForm.controls.name.reset()"
                                >
                                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                                </button>
                            }
                        </mat-form-field>

                        <!-- Suchkriterium Geburtsdatum -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-geburtsdatum-label">Geburtsdatum</mat-label>
                            <input
                                id="einwohner-detail-search-geburtsdatum-input"
                                matInput
                                type="text"
                                formControlName="geburtsdatum"
                                autocomplete="off"
                                aria-labelledby="einwohner-detail-search-geburtsdatum-label"
                                data-cy="einwohner-detail-search-geburtsdatum-input"
                            />
                            @if (detailSearchForm.controls.geburtsdatum.value) {
                                <button
                                    tabindex="-1"
                                    type="button"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="detailSearchForm.controls.geburtsdatum.reset()"
                                >
                                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                                </button>
                            }
                        </mat-form-field>

                        <!-- Suchkriterien Wohnsitz -->
                        <h3 class="text-xl">Wohnsitz</h3>

                        <!-- Wohnsitzqualität -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-wohnsitzqualitaet-label">Wohnsitzqualität</mat-label>
                            <mat-select
                                id="einwohner-detail-search-wohnsitzqualitaet-select"
                                matInput
                                multiple
                                formControlName="wohnsitzqualitaet"
                                aria-labelledby="einwohner-detail-search-wohnsitzqualitaet-label"
                                k5StopEnterPropagation
                                data-cy="einwohner-detail-search-wohnsitzqualitaet-select"
                            >
                                @for (item of Wohnsitzqualitaet | enumKeys; track $index) {
                                    <mat-option
                                        [value]="item"
                                        data-cy="einwohner-detail-search-wohnsitzqualitaet-option"
                                    >
                                        {{ item | wohnsitzqualitaet }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <!-- Suchkriterium Meldeadresse -->
                        <mat-form-field class="w-full">
                            <mat-label id="einwohner-detail-search-meldeadresse-label">Meldeadresse</mat-label>
                            <input
                                id="einwohner-detail-search-meldeadresse-input"
                                matInput
                                type="text"
                                formControlName="meldeadresse"
                                autocomplete="off"
                                aria-labelledby="einwohner-detail-search-meldeadresse-label"
                                data-cy="einwohner-detail-search-meldeadresse-input"
                            />
                            @if (detailSearchForm.controls.meldeadresse.value) {
                                <button
                                    tabindex="-1"
                                    type="button"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="detailSearchForm.controls.meldeadresse.reset()"
                                >
                                    <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base"></mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </form>
                </div>
            </k5next-search-panel>
        </aside>
    }
</div>
