import { Pipe, type PipeTransform } from '@angular/core';
import { Familienstand } from '../models/enums/familienstand';

@Pipe({
    name: 'familienstand',
    standalone: true,
    pure: true
})
export class FamilienstandPipe implements PipeTransform {
    /**
     * Übersetzt Familienstand aus dem Backend in eine textuelle Form
     * @param value Familienstand
     */
    transform(value: Familienstand): string {
        switch (value) {
            case Familienstand.Ledig:
                return 'ledig';
            case Familienstand.Verheiratet:
                return 'verheiratet';
            case Familienstand.EingetragenePartnerschaft:
                return 'in eingetragener Partnerschaft lebend';
            case Familienstand.Geschieden:
                return 'geschieden';
            case Familienstand.EingetragenePartnerschaftGeloest:
                return 'eingetragene Partnerschaft aufgelöst oder für nichtig erklärt';
            case Familienstand.Verwitwet:
                return 'verwitwet';
            case Familienstand.HinterbliebenerPartner:
                return 'hinterbliebener eingetragener Partner';
            case Familienstand.EheAufgeboben:
                return 'Ehe aufgehoben oder für nichtig erklärt';
            case Familienstand.Unbekannt:
                return 'Unbekannt';
            default:
                return 'Ungültig';
        }
    }
}
