import { Component, Input } from '@angular/core';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base';

@Component({
    selector: 'k5-menu-avatar-item',
    templateUrl: './menu-avatar-item.component.html'
})
export class MenuAvatarItemComponent extends MenuItemBaseComponent {
    @Input()
    initials: string = '';

    @Input()
    bgColor: string = '#000000';
}
