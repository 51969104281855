<k5-menu-item-base [description]="description" [loading]="loading" [disabled]="disabled" [tooltip]="tooltip">
    <div
        *ngIf="initials"
        class="flex h-8 w-8 items-center justify-center rounded-full"
        [ngStyle]="{ 'background-color': bgColor }"
    >
        <div class="text-lg text-white">
            {{ initials }}
        </div>
    </div>
</k5-menu-item-base>
