import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WohnsitzStatusFilter } from '../models/enums/wohnsitzStatusFilter';
import { WohnsitzDetailDto } from '../models/wohnsitzDetailDto';

@Injectable({
    providedIn: 'root'
})
export class WohnsitzeService {
    private readonly baseUrl: string;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService
    ) {
        this.baseUrl = this.configService.getConfig().k5Meldewesen.meldewesenUiApiUrl;
    }

    /**
     * Liefert die Wohnsitze eines Einwohners für die Detailansicht
     * @param mandantId Id des Mandanten
     * @param einwohnerId Id des Einwohners
     * @param wohnsitzStatusFilters Optionale Filter Wohnsitze Aktuell/Historisch
     * @returns Wohnsitze des Einwohners
     */
    getWohnsitzeOfEinwohner(
        mandantId: string,
        einwohnerId: string,
        wohnsitzStatusFilters: WohnsitzStatusFilter[]
    ): Observable<WohnsitzDetailDto[]> {
        let params = new HttpParams();

        wohnsitzStatusFilters.forEach((filter: WohnsitzStatusFilter) => {
            params = params.append('wohnsitzStatusFilters', filter);
        });

        return this.httpClient.get<WohnsitzDetailDto[]>(
            `${this.baseUrl}/v1/mandanten/${mandantId}/einwohner/${einwohnerId}/wohnsitze`,
            { params }
        );
    }
}
