/**
 * Status-Filter für die Wohnsitze eines Einwohners
 */
export enum WohnsitzStatusFilter {
    /**
     * Aktuelle Wohnsitze anzeigen
     */
    Aktuell = 1,

    /**
     * Historische Wohnsitze anzeigen
     */
    Historisch = 2
}
