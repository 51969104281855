/**
 * Mögliche Qualitäten eines Wohnsitzes in einer Meldung
 */
export enum Wohnsitzqualitaet {
    /**
     * Hauptwohnsitz
     * @remarks Wert im ZMR: "H"
     */
    Hauptwohnsitz = 1,

    /**
     * Nebenwohnsitz
     * @remarks Wert im ZMR: "N"
     */
    Nebenwohnsitz = 2,

    /**
     * Obdachlos
     * @remarks Wert im ZMR: "O"
     */
    Obdachlos = 3
}
