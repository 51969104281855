// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base';

@Component({
    selector: 'k5-menu-image-item',
    templateUrl: './menu-image-item.component.html'
})
export class MenuImageItemComponent extends MenuItemBaseComponent {
    @Input()
    imageUrl: string;
}
