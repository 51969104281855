import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { Subscription } from 'rxjs/internal/Subscription';
import { EinwohnerService } from '../../services/einwohner.service';
import { EinwohnerDetailDto } from '../../models/response/einwohnerDetailDto';
import { AlertService } from '@core/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserInformationService } from '@core/services/user-information.service';
import { CommonModule } from '@angular/common';
import { FamilienstandPipe } from '../../pipes/familienstand.pipe';
import { EINWOHNER_ID } from '../../constants/meldewesen.constants';
import { WohnsitzStatusFilterPipe } from '../../pipes/wohnsitz-status-filter.pipe';
import { of, switchMap } from 'rxjs';
import { EinwohnerDetailWohnsitzeComponent } from '../einwohner-detail-wohnsitze/einwohner-detail-wohnsitze.component';

@Component({
    selector: 'k5-einwohner-detail',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, SharedModule, MatTabsModule, FamilienstandPipe, EinwohnerDetailWohnsitzeComponent],
    providers: [WohnsitzStatusFilterPipe],
    templateUrl: './einwohner-detail.component.html'
})
export class EinwohnerDetailComponent implements OnInit, OnDestroy {
    private readonly subscription: Subscription = new Subscription();

    loading = signal<boolean>(false);
    error = signal<boolean>(false);

    mandantId = signal<string>('');
    einwohner = signal<EinwohnerDetailDto | null>(null);
    header = computed(() => {
        const einwohner = this.einwohner();
        return einwohner ? `${einwohner.vorname} ${einwohner.familienname}` : '';
    });

    constructor(
        private readonly route: ActivatedRoute,
        private readonly userInformationService: UserInformationService,
        private readonly einwohnerService: EinwohnerService,
        private readonly alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.route.paramMap
                .pipe(
                    switchMap((params: ParamMap) => {
                        this.mandantId.set(this.userInformationService.getMandantId());

                        const einwohnerId: string | null = params.get(`${EINWOHNER_ID}`);
                        if (einwohnerId === null) {
                            return of(null);
                        }

                        return this.einwohnerService.getEinwohnerDetail(this.mandantId(), einwohnerId);
                    })
                )
                .subscribe({
                    next: (einwohner: EinwohnerDetailDto | null) => {
                        if (einwohner === null) {
                            this.alertService.error('Fehler beim Laden des Einwohners');
                            this.loading.set(false);
                            this.error.set(true);
                            return;
                        }

                        this.einwohner.set(einwohner);
                        this.loading.set(false);
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorResponse(error, 'Fehler beim Laden des Einwohners');
                        this.loading.set(false);
                        this.error.set(true);
                    }
                })
        );
    }

    /**
     * Aufheben der Subscriptions
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
