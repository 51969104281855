<div class="relative size-full">
    @if (loading()) {
        <div class="absolute z-10 flex size-full items-center">
            <k5-loading message="Einwohner werden geladen ..."> </k5-loading>
        </div>
    }

    @if (displayedColumns$ | async; as columns) {
        @if (!loading() && tableStateService.loadedRows() === 0) {
            <div class="flex size-full items-center justify-center">
                <span class="text-lg font-bold">Kein Suchergebnis gefunden</span>
            </div>
        } @else if (tableStateService.loadedRows() > 0) {
            <k5next-table
                [ngClass]="{ 'blur-effect': loading() }"
                [trackByFn]="trackByFn"
                [columns]="columns"
                [columnGroups]="columnGroups"
                [dataSource]="dataSource"
                [rowSelectionEnabled]="true"
                [columnSelectionEnabled]="true"
                [editPencilColumnIndex]="1"
                [menuTemplateRef]="menuContent"
                matSort
                matSortDisableClear
                (matSortChange)="onSortChanged($event)"
                (loadNextPage)="loadNextPage()"
                (editRow)="navigateToMeldung($event)"
                (saveColumns)="saveColumns($event)"
                (resetColumns)="resetColumns()"
            >
                <!-- ZMR Zahl -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_ZMRZAHL">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_ZMRZAHL }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.zmrZahl }}
                    </td>
                </ng-container>

                <!-- Name -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_NAME">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_NAME }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name }}
                    </td>
                </ng-container>

                <!-- Geburtsdatum -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_GEBURTSDATUM">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_GEBURTSDATUM }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.geburtsdatum | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
                    </td>
                </ng-container>

                <!-- Wohnsitzqualität -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_WOHNSITZQUALITAET">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_WOHNSITZQUALITAET }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.wohnsitz?.wohnsitzqualitaet | wohnsitzqualitaetShort }}
                    </td>
                </ng-container>

                <!-- Adresse -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_ADRESSE">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_ADRESSE }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.wohnsitz?.adresse }}
                    </td>
                </ng-container>

                <!-- Anmeldedatum -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_GEMELDET_VON">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_GEMELDET_VON }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.wohnsitz?.gemeldetVon | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
                    </td>
                </ng-container>

                <!-- Abmeldedatum -->
                <ng-container [matColumnDef]="EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_KEY_GEMELDET_BIS">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ EINWOHNER_TABLE_CONSTANTS.EINWOHNER_COLUMN_TITLE_GEMELDET_BIS }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.wohnsitz?.gemeldetBis | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
                    </td>
                </ng-container>
            </k5next-table>
        }
    }
</div>

<!-- Aktionen am Ende einer Zeile -->
<ng-template #menuContent let-element="row">
    <div class="flex">
        <!-- Personendaten verändern -->
        <button
            type="button"
            aria-label="Personendaten verändern"
            mat-icon-button
            color="primary"
            matTooltip="Personendaten verändern"
            (click)="changePersonendaten(element)"
        >
            <i class="fa-duotone fa-user-pen fg-primary text-base"></i>
        </button>

        <!-- Wohnsitze verändern -->
        <button
            type="button"
            aria-label="Wohnsitze verändern"
            mat-icon-button
            color="primary"
            matTooltip="Wohnsitze verändern"
            (click)="changeWohnsitze(element)"
        >
            <i class="fa-duotone fa-house-chimney-user fg-primary text-base"></i>
        </button>
    </div>
</ng-template>
