<div class="px-4">
    <header>
        <k5-responsive-title>
            <k5-page-header header="Meldewesen - Konfiguration" icon="fa-duotone fa-sliders-h fg-primary">
            </k5-page-header>
        </k5-responsive-title>
    </header>
    <main>
        <k5-section-header [title]="'Datenmigration'" icon="fa-upload"></k5-section-header>

        @if (loading()) {
            <div class="py-4">
                <k5-loading message="Datenmigrationsstatus wird geladen ..."></k5-loading>
            </div>
        }

        @if (datenmigration() && !loading()) {
            @if (DATENMIGRATION_STATUS.NotStarted) {
                <div class="p-4">
                    <p class="font-bold">Keine Daten vorhanden</p>
                    <p class="pb-2">Beginnen Sie mit der Datenmigration indem Sie eine Datei hochladen</p>
                    <button mat-raised-button color="primary" type="button" (click)="openFileUploadDialog()">
                        Datei hochladen
                    </button>
                </div>
            } @else if (DATENMIGRATION_STATUS.Completed && datenmigration().personCount === 0) {
                <div class="p-4">
                    <p class="font-bold">Import der LMR-Daten abgeschlossen</p>
                    <p>Anzahl der verarbeiteten Meldungssätze: {{ datenmigration().meldungExtCount }}</p>
                    <p class="pb-2">Anzahl der verarbeiteten Personensätze: {{ datenmigration().personExtCount }}</p>

                    <button mat-flat-button color="primary" type="button" (click)="migrateData()">
                        Datenmigration durchführen
                    </button>

                    <button
                        class="ml-2"
                        mat-stroked-button
                        color="primary"
                        type="button"
                        (click)="downloadMigrationsProtokoll()"
                    >
                        Migrationsprotokoll herunterladen
                    </button>

                    <button class="ml-2" mat-button color="primary" type="button" (click)="openFileUploadDialog()">
                        Neue Datei hochladen
                    </button>
                </div>
            } @else if (DATENMIGRATION_STATUS.Completed && datenmigration().personCount > 0) {
                <div class="p-4">
                    <p class="font-bold">Datenmigration abgeschlossen</p>
                    <p>Anzahl der verarbeiteten Meldungssätze: {{ datenmigration().meldungExtCount }}</p>
                    <p>Anzahl der verarbeiteten Personensätze: {{ datenmigration().personExtCount }}</p>
                    <p class="pb-2">Anzahl der migrierten Personensätze: {{ datenmigration().personCount }}</p>

                    <button mat-flat-button color="primary" type="button" (click)="migrateData()">
                        Datenmigration durchführen
                    </button>

                    <button
                        class="ml-2"
                        mat-stroked-button
                        color="primary"
                        type="button"
                        (click)="downloadMigrationsProtokoll()"
                    >
                        Migrationsprotokoll herunterladen
                    </button>

                    <button class="ml-2" mat-button color="primary" type="button" (click)="openFileUploadDialog()">
                        Neue Datei hochladen
                    </button>
                </div>
            }
        }
    </main>
</div>
