import { SelectionChange } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiConstants } from '@core/constants/ui-constants';
import { AlertService } from '@core/services/alert.service';
import { UserInformationService } from '@core/services/user-information.service';
import { K5NextDataSource, TableComponent } from '@k5next/ui-components';
import { TableState, TableStateService } from '@shared/services/table-state.service';
import { catchError, Observable, of, Subscription, switchMap } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { WohnsitzqualitaetShortPipe } from '../../pipes/wohnsitzqualitaet-short.pipe';
import { EinwohnerGridDefinition } from './einwohner-grid-definition';
import { EinwohnerTableDto } from '../../models/einwohnerTableDto';
import { EinwohnerService } from '../../services/einwohner.service';
import { EinwohnerResultDto } from '../../models/response/einwohnerResultDto';
import { Router } from '@angular/router';
import { MELDEWESEN_EINWOHNER, MELDEWESEN_ROOT } from '../../constants/meldewesen.constants';

@Component({
    selector: 'k5-einwohner-table',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        SharedModule,
        MatTableModule,
        MatSortModule,
        TableComponent,
        MatTooltipModule,
        MatButtonModule,
        WohnsitzqualitaetShortPipe
    ],
    templateUrl: './einwohner-table.component.html',
    styleUrl: './einwohner-table.component.scss'
})
export class EinwohnerTableComponent implements OnInit, OnDestroy {
    private readonly userInformationService = inject(UserInformationService);
    private readonly tableStateService = inject(TableStateService<EinwohnerTableDto>);
    private readonly einwohnerService = inject(EinwohnerService);
    private readonly alertService = inject(AlertService);
    private readonly router = inject(Router);

    private tableStateSubscription: Subscription | null = null;
    private selectionSubscription: Subscription | null = null;

    loading: WritableSignal<boolean> = signal(true);

    EINWOHNER_TABLE_CONSTANTS = EinwohnerGridDefinition;

    dataSource = new K5NextDataSource<EinwohnerTableDto>();
    columnGroups = EinwohnerGridDefinition.columnGroups;

    get displayedColumns$(): Observable<string[]> {
        return this.tableStateService.displayedColumns$;
    }

    ngOnInit(): void {
        // Ermitteln der ausgewählten Spalten
        this.userInformationService
            .getBenutzerEinstellungFromService<string[]>(UiConstants.EINWOHNER_COLUMNS)
            .subscribe({
                next: (eiwColumnSettings: string[] | null) => {
                    // Bei keiner Auswahl => Default Werte der Anwendung
                    const displayColumns: string[] =
                        eiwColumnSettings && eiwColumnSettings.length > 0
                            ? eiwColumnSettings
                            : EinwohnerGridDefinition.EINWOHNER_DEFAULT_COLUMNS;

                    this.tableStateService.updateDisplayedColumns(displayColumns);
                },
                error: (error: HttpErrorResponse) => {
                    this.tableStateService.updateDisplayedColumns(EinwohnerGridDefinition.EINWOHNER_DEFAULT_COLUMNS);
                    this.alertService.errorResponse(error, 'Fehler beim Laden der Spaltenauswahl');
                }
            });

        // Subscription für den Zustand der Tabelle
        this.tableStateSubscription = this.tableStateService.state$
            .pipe(
                switchMap((tableState: TableState) => {
                    this.loading.set(true);
                    const mandantId: string = this.userInformationService.getMandantId();

                    return this.einwohnerService.getEinwohnerTableData(mandantId, {
                        sortBy: tableState.sort.active,
                        sortDescending: tableState.sort.direction === 'desc',
                        suchkriterien: tableState.suchkriterien,
                        pageNumber: tableState.pageNumber,
                        pageSize: this.tableStateService.pageSize
                    });
                }),
                catchError((error: HttpErrorResponse) => {
                    this.loading.set(false);
                    this.alertService.errorResponse(error, 'Fehler beim Laden der Einwohner');
                    return of(null);
                })
            )
            .subscribe({
                next: (result: EinwohnerResultDto | null) => {
                    if (!result) {
                        this.tableStateService.totalRows.set(0);
                        this.tableStateService.loadedRows.set(0);
                        this.tableStateService.resetPage();
                        this.dataSource.resetDataSource();
                        this.loading.set(false);
                        return;
                    }

                    this.tableStateService.totalRows.set(result.totalRows);
                    if (this.tableStateService.tableState.pageNumber === 0) {
                        this.dataSource.resetDataSource(result.einwohner);
                        this.tableStateService.loadedRows.set(result.einwohner.length);
                    } else {
                        this.dataSource.addPage(result.einwohner);
                        this.tableStateService.loadedRows.set(this.dataSource.data.length);
                    }

                    this.loading.set(false);
                }
            });

        // Subscription für die ausgewählten Einträge in der Tabelle
        this.selectionSubscription = this.dataSource.selection.changed.subscribe({
            next: (selectionChange: SelectionChange<EinwohnerTableDto>) => {
                this.tableStateService.updateSelectionState(
                    selectionChange.source.selected,
                    this.dataSource.invertedSelection,
                    this.dataSource.invertedSelectionModeActive
                );
            }
        });
    }

    /**
     * Aufheben der Subscriptions
     */
    ngOnDestroy(): void {
        this.tableStateSubscription?.unsubscribe();
        this.selectionSubscription?.unsubscribe();
    }

    /**
     * Gibt die Id des PersonEntity zurück, um dieses in der Liste eindeutig zu identifizieren
     * @param item Einwohner in der Tabelle
     * @returns Id des PersonEntity
     */
    trackByFn(item: EinwohnerTableDto): string {
        return item.id;
    }

    /**
     * Ändert die Sortierung der Tabelle
     * @param sort Sortierinformationen
     */
    onSortChanged(sort: MatSort): void {
        this.tableStateService.updateSort(sort);
    }

    /**
     * Lädt die nächste Seite der Tabelle
     */
    loadNextPage(): void {
        this.tableStateService.loadNextPage();
    }

    /**
     * Navigiert zur Detailansicht des ausgewählten Einwohner
     * @param einwohner EinwohnerTableDto
     */
    navigateToMeldung(einwohner: EinwohnerTableDto): void {
        this.router.navigate([MELDEWESEN_ROOT, MELDEWESEN_EINWOHNER, einwohner.id]);
    }

    /**
     * Verändert die Personendaten des ausgewählten Einwohner
     * @param einwohner EinwohnerTableDto
     * @remarks Funktion noch nicht implementiert
     */
    changePersonendaten(einwohner: EinwohnerTableDto): void {
        console.log(einwohner);
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Verändert die Wohnsitze des ausgewählten Einwohner
     * @param einwohner EinwohnerTableDto
     * @remarks Funktion noch nicht implementiert
     */
    changeWohnsitze(einwohner: EinwohnerTableDto): void {
        console.log(einwohner);
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Speichert die Spalten in den Benutzereinstellungen und aktualisiert das UI
     * @param selectedColumns Ausgewählte Spalten
     */
    saveColumns(selectedColumns: string[]): void {
        this.userInformationService.saveBenutzerEinstellung(selectedColumns, UiConstants.EINWOHNER_COLUMNS).subscribe({
            next: () => {
                this.tableStateService.updateDisplayedColumns(selectedColumns);
                this.alertService.success('Spaltenauswahl gespeichert');
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Speichern der Spaltenauswahl');
            }
        });
    }

    /**
     * Setzt die Spaltenauswahl zurück auf die Default Werte der Anwendung und aktualisiert das UI
     */
    resetColumns(): void {
        this.saveColumns(EinwohnerGridDefinition.EINWOHNER_DEFAULT_COLUMNS);
    }
}
