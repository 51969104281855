/**
 * Familienstand eines Einwohners
 */
export enum Familienstand {
    /**
     * Ledig
     */
    Ledig = 1,

    /**
     * Verheiratet
     */
    Verheiratet = 2,

    /**
     * In eingetragener Partnerschaft lebend
     * @remarks Wert im ZMR: "ineingetragenerPartnerschaftlebend"
     */
    EingetragenePartnerschaft = 3,

    /**
     * Geschieden
     */
    Geschieden = 4,

    /**
     * Eingetragene Partnerschaft aufgelöst oder für nichtig erklärt
     * @remarks Wert im ZMR: "eingetragenePartnerschaftaufgelöstoderfürnichtigerklärt"
     */
    EingetragenePartnerschaftGeloest = 5,

    /**
     * Verwitwet
     */
    Verwitwet = 6,

    /**
     * Hinterbliebener eingetragener Partner
     * @remarks Wert im ZMR: "hinterbliebenereingetragenerPartner"
     */
    HinterbliebenerPartner = 7,

    /**
     * Ehe aufgehoben oder für nichtig erklärt
     * @remarks Wert im ZMR: "Eheaufgehobenoderfürnichtigerklärt"
     */
    EheAufgeboben = 8,

    /**
     * Unbekannt
     */
    Unbekannt = 99
}
