import { Sort } from '@angular/material/sort';
import { OptionGroup } from '@k5next/ui-components/lib/grid/components/chips-autocomplete/models/chips-autocomplete-options-interfaces';

/**
 * Definition der notwendigen Werte für die Grid-Komponente
 */
export class EinwohnerGridDefinition {
    //#region Spaltenkonstanten

    static readonly EINWOHNER_COLUMN_KEY_ZMRZAHL = 'zmrZahl';
    static readonly EINWOHNER_COLUMN_TITLE_ZMRZAHL = 'ZMR Zahl';
    static readonly EINWOHNER_COLUMN_KEY_NAME = 'name';
    static readonly EINWOHNER_COLUMN_TITLE_NAME = 'Name';
    static readonly EINWOHNER_COLUMN_KEY_GEBURTSDATUM = 'geburtsdatum';
    static readonly EINWOHNER_COLUMN_TITLE_GEBURTSDATUM = 'Geburtsdatum';
    static readonly EINWOHNER_COLUMN_KEY_WOHNSITZQUALITAET = 'wohnsitzqualitaet';
    static readonly EINWOHNER_COLUMN_TITLE_WOHNSITZQUALITAET = 'WQ';
    static readonly EINWOHNER_COLUMN_KEY_ADRESSE = 'adresse';
    static readonly EINWOHNER_COLUMN_TITLE_ADRESSE = 'Adresse';
    static readonly EINWOHNER_COLUMN_KEY_GEMELDET_VON = 'gemeldetVon';
    static readonly EINWOHNER_COLUMN_TITLE_GEMELDET_VON = 'gem. von';
    static readonly EINWOHNER_COLUMN_KEY_GEMELDET_BIS = 'gemeldetBis';
    static readonly EINWOHNER_COLUMN_TITLE_GEMELDET_BIS = 'gem. bis';

    static readonly EINWOHNER_DEFAULT_COLUMNS: string[] = [
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_ZMRZAHL,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_NAME,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_GEBURTSDATUM,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_WOHNSITZQUALITAET,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_ADRESSE,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_GEMELDET_VON,
        EinwohnerGridDefinition.EINWOHNER_COLUMN_KEY_GEMELDET_BIS
    ];

    //#endregion

    private static readonly _columnGroups: OptionGroup[] = [
        {
            name: 'Person',
            options: [
                {
                    key: this.EINWOHNER_COLUMN_KEY_ZMRZAHL,
                    value: this.EINWOHNER_COLUMN_TITLE_ZMRZAHL
                },
                {
                    key: this.EINWOHNER_COLUMN_KEY_NAME,
                    value: this.EINWOHNER_COLUMN_TITLE_NAME
                },
                {
                    key: this.EINWOHNER_COLUMN_KEY_GEBURTSDATUM,
                    value: this.EINWOHNER_COLUMN_TITLE_GEBURTSDATUM
                }
            ]
        },
        {
            name: 'Wohnsitz',
            options: [
                {
                    key: this.EINWOHNER_COLUMN_KEY_WOHNSITZQUALITAET,
                    value: this.EINWOHNER_COLUMN_TITLE_WOHNSITZQUALITAET
                },
                {
                    key: this.EINWOHNER_COLUMN_KEY_ADRESSE,
                    value: this.EINWOHNER_COLUMN_TITLE_ADRESSE
                },
                {
                    key: this.EINWOHNER_COLUMN_KEY_GEMELDET_VON,
                    value: this.EINWOHNER_COLUMN_TITLE_GEMELDET_VON
                },
                {
                    key: this.EINWOHNER_COLUMN_KEY_GEMELDET_BIS,
                    value: this.EINWOHNER_COLUMN_TITLE_GEMELDET_BIS
                }
            ]
        }
    ];

    /**
     * Gibt die Standardsortierung zurück
     */
    static get defaultSorting(): Sort {
        return { active: this.EINWOHNER_COLUMN_KEY_NAME, direction: 'asc' };
    }

    /**
     * Liefert die Spaltengruppen für die Spaltenauswahl zurück
     */
    static get columnGroups(): OptionGroup[] {
        return this._columnGroups;
    }
}
