import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertService } from '@core/services/alert.service';
import { SearchPanelComponent } from '@k5next/ui-components';
import { TableState, TableStateService } from '@shared/services/table-state.service';
import { SharedModule } from '@shared/shared.module';
import { Subscription } from 'rxjs';
import { MenuModule } from '../../../shared/modules/menu/menu.module';
import {
    bpkzp,
    geburtsdatum,
    meldeadresse,
    name,
    wohnsitzqualitaet,
    zmrZahl
} from '../../constants/meldewesen.constants';
import { Wohnsitzqualitaet } from '../../models/enums/wohnsitzqualitaet';
import { WohnsitzqualitaetPipe } from '../../pipes/wohnsitzqualitaet.pipe';
import { EinwohnerGridDefinition } from '../einwohner-table/einwohner-grid-definition';
import { EinwohnerTableComponent } from '../einwohner-table/einwohner-table.component';
import { EinwohnerTableDto } from '../../models/einwohnerTableDto';

@Component({
    selector: 'k5-einwohner',
    standalone: true,
    imports: [
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        OverlayModule,
        MenuModule,
        EinwohnerTableComponent,
        SearchPanelComponent,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatSelectModule,
        WohnsitzqualitaetPipe
    ],
    providers: [
        {
            provide: TableStateService<EinwohnerTableDto>,
            useFactory: () =>
                new TableStateService<EinwohnerTableDto>(
                    EinwohnerGridDefinition.EINWOHNER_DEFAULT_COLUMNS,
                    EinwohnerGridDefinition.defaultSorting
                )
        }
    ],
    templateUrl: './einwohner.component.html',
    styleUrls: ['./einwohner.component.scss']
})
export class EinwohnerComponent implements OnInit, OnDestroy {
    readonly tableStateService = inject(TableStateService<EinwohnerTableDto>);
    private readonly alertService = inject(AlertService);

    detailSearchOpen: WritableSignal<boolean> = signal(true);

    detailSearchForm = new FormGroup({
        enableZmrSearch: new FormControl<boolean>(false, { nonNullable: true }),
        zmrZahl: new FormControl<string | null>(null),
        bpkzp: new FormControl<string | null>(null),
        name: new FormControl<string | null>(null),
        geburtsdatum: new FormControl<string | null>(null),
        wohnsitzqualitaet: new FormControl<Wohnsitzqualitaet[] | null>(null),
        meldeadresse: new FormControl<string | null>(null)
    });

    // Konstante zur Verwendung im Template
    Wohnsitzqualitaet = Wohnsitzqualitaet;

    private tableStateSubscription: Subscription | null = null;

    ngOnInit(): void {
        // Subscription für den Zustand der Tabelle
        this.tableStateSubscription = this.tableStateService.state$.subscribe((state: TableState) => {
            // Übernahme der Suchkriterien in das Form
            this.#mapSuchkriterienToFormFields(state.suchkriterien);
        });
    }

    /**
     * Aufheben der Subscriptions
     */
    ngOnDestroy(): void {
        this.tableStateSubscription?.unsubscribe();
    }

    /**
     * Öffnet Standardbericht mit den ausgewählten Einwohner
     * @remarks Funktion noch nicht implementiert
     */
    navigateToStandardbericht(): void {
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Download Excel Datei mit den ausgewählten Einwohner
     * @remarks Funktion noch nicht implementiert
     */
    downloadExcel(): void {
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Öffnet Meldebestätigung mit den ausgewählten Einwohner
     * @remarks Funktion noch nicht implementiert
     */
    navigateToMeldebestaetigung(): void {
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Blendet die Detailsuche aus
     */
    hideDetailsearch(): void {
        this.detailSearchOpen.set(false);
    }

    /**
     * Setzt die Detailsuche zurück
     */
    resetSuche(): void {
        this.tableStateService.resetState();
    }

    /**
     * Führt eine Detailsuche durch
     */
    executeSuche(): void {
        const suchkriterien: { [key: string]: string } = {};

        if (this.detailSearchForm.controls.zmrZahl.value) {
            suchkriterien[zmrZahl] = this.detailSearchForm.controls.zmrZahl.value;
        }

        if (this.detailSearchForm.controls.bpkzp.value) {
            suchkriterien[bpkzp] = this.detailSearchForm.controls.bpkzp.value;
        }

        if (this.detailSearchForm.controls.name.value) {
            suchkriterien[name] = this.detailSearchForm.controls.name.value;
        }

        if (this.detailSearchForm.controls.geburtsdatum.value) {
            suchkriterien[geburtsdatum] = this.detailSearchForm.controls.geburtsdatum.value;
        }

        if (this.detailSearchForm.controls.wohnsitzqualitaet.value) {
            suchkriterien[wohnsitzqualitaet] = this.detailSearchForm.controls.wohnsitzqualitaet.value.join(',');
        }

        if (this.detailSearchForm.controls.meldeadresse.value) {
            suchkriterien[meldeadresse] = this.detailSearchForm.controls.meldeadresse.value;
        }

        const page: number = 0;
        const previousTableState: TableState = this.tableStateService.tableState;

        this.tableStateService.updateState(
            page,
            previousTableState.sort,
            previousTableState.schnellsucheQuery,
            suchkriterien
        );
    }

    //#region Hilfsfunktionen

    /**
     * Übernimmt Key-Value-Pair Suchkriterien der Detailsuche in das Form
     * @param suchkriterien Suchkriterien der Detailsuche
     */
    #mapSuchkriterienToFormFields(suchkriterien: { [key: string]: string } | null): void {
        if (!suchkriterien || Object.keys(suchkriterien).length === 0) {
            this.detailSearchForm.reset();
            return;
        }

        for (const key in suchkriterien) {
            switch (key) {
                case zmrZahl: {
                    this.detailSearchForm.controls.zmrZahl.setValue(suchkriterien[key]);
                    break;
                }
                case bpkzp: {
                    this.detailSearchForm.controls.bpkzp.setValue(suchkriterien[key]);
                    break;
                }
                case name: {
                    this.detailSearchForm.controls.name.setValue(suchkriterien[key]);
                    break;
                }
                case geburtsdatum: {
                    this.detailSearchForm.controls.geburtsdatum.setValue(suchkriterien[key]);
                    break;
                }
                case wohnsitzqualitaet: {
                    this.detailSearchForm.controls.wohnsitzqualitaet.setValue(
                        suchkriterien[key].split(',').map((value) => +value)
                    );
                    break;
                }
                case meldeadresse: {
                    this.detailSearchForm.controls.meldeadresse.setValue(suchkriterien[key]);
                    break;
                }
                default: {
                    this.alertService.warning(`Unbekanntes Suchkriterium: ${key}`);
                    break;
                }
            }
        }
    }

    //#endregion
}
