@if (wohnsitze().length === 0) {
    <div class="text-center text-lg font-bold">Keine Wohnsitze gefunden</div>
} @else {
    <k5next-table
        [trackByFn]="trackByFn"
        [columns]="columns"
        [dataSource]="dataSource()"
        [rowSelectionEnabled]="false"
        [columnSelectionEnabled]="false"
        [menuTemplateRef]="menuContent"
    >
        <!-- Wohnsitzqualität -->
        <ng-container [matColumnDef]="EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_KEY_WOHNSITZQUALITAET">
            <th mat-header-cell *matHeaderCellDef>
                {{ EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_TITLE_WOHNSITZQUALITAET }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.wohnsitzqualitaet | wohnsitzqualitaet }}
            </td>
        </ng-container>

        <!-- Adresse -->
        <ng-container [matColumnDef]="EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_KEY_ADRESSE">
            <th mat-header-cell *matHeaderCellDef>
                {{ EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_TITLE_ADRESSE }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.adresse }}
            </td>
        </ng-container>

        <!-- Gemeldet von -->
        <ng-container [matColumnDef]="EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_VON">
            <th mat-header-cell *matHeaderCellDef>
                {{ EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_TITLE_GEMELDET_VON }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.gemeldetVon | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
            </td>
        </ng-container>

        <!-- Gemeldet bis -->
        <ng-container [matColumnDef]="EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_BIS">
            <th mat-header-cell *matHeaderCellDef>
                {{ EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_TITLE_GEMELDET_BIS }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.gemeldetBis | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
            </td>
        </ng-container>

        <!-- Adressstatus -->
        <ng-container [matColumnDef]="EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_KEY_ADRESSSTATUS">
            <th mat-header-cell *matHeaderCellDef>
                {{ EINWOHNER_DETAIL_TABLE_CONSTANTS.EINWOHNER_DETAIL_COLUMN_TITLE_ADRESSSTATUS }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.adressstatus }}
            </td>
        </ng-container>
    </k5next-table>
}

<!-- Aktionen am Ende einer Zeile -->
<ng-template #menuContent let-element="row">
    <div class="flex">
        <!-- Wohnsitz abmelden -->
        <button
            type="button"
            aria-label="Wohnsitz abmelden"
            mat-icon-button
            color="primary"
            matTooltip="Wohnsitz abmelden"
            (click)="wohnsitzAbmelden(element)"
        >
            <i class="fa-duotone fa-house-circle-xmark fg-primary text-base"></i>
        </button>

        <!-- Wohnsitz ummelden -->
        <button
            type="button"
            aria-label="Wohnsitz ummelden"
            mat-icon-button
            color="primary"
            matTooltip="Wohnsitz ummelden"
            (click)="wohnsitzUmmelden(element)"
        >
            <i class="fa-duotone fa-house-circle-exclamation fg-primary text-base"></i>
        </button>
    </div>
</ng-template>
