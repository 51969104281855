import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatRippleModule } from '@angular/material/core';
import { A11yModule } from '@angular/cdk/a11y';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MenuAvatarItemComponent } from './menu-avatar-item/menu-avatar-item.component';
import { MenuImageItemComponent } from './menu-image-item/menu-image-item.component';
import { MenuItemBaseComponent } from './menu-item-base/menu-item-base';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        MenuComponent,
        MenuGroupComponent,
        MenuItemComponent,
        MenuAvatarItemComponent,
        MenuImageItemComponent,
        MenuItemBaseComponent
    ],
    imports: [CommonModule, OverlayModule, MatRippleModule, A11yModule, MatProgressSpinnerModule, MatTooltipModule],
    exports: [MenuComponent, MenuGroupComponent, MenuItemComponent, MenuAvatarItemComponent, MenuImageItemComponent]
})
export class MenuModule {}
