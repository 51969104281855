// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UiConstants } from '@core/constants/ui-constants';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { MandantenService } from '@core/mandanten.service';
import { KeyValuePair } from '@core/models/key-value';
import { NAV_ITEMS, NavItem, WAHL_CONFIGURATION_NAV_ITEM, WAHL_HISTORISCH_NAV_ITEM } from '@core/models/nav-item';
import { NavigationService } from '@core/services/navigation.service';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { MandantClient } from '@shared/models/mandantenClient';
import { Wahldaten } from '@shared/models/wahldaten';
import { CapabilitiesService } from '@shared/services/capabilities.service';
import { SchnellsucheService } from '@shared/services/schnellsuche.service';
import { AlertService } from './alert.service';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

export enum State {
    Initial,
    Loading,
    Finished,
    Error
}

@Injectable({
    providedIn: 'root'
})
export class UserInformationService {
    private uiInitSubject = new BehaviorSubject<MandantClient.CivisInitialisierung>(null);
    public uiInitState$ = this.uiInitSubject.asObservable();
    private userRechteSubject = new BehaviorSubject<string[]>(null);
    public userRechte$ = this.userRechteSubject.asObservable();
    private wahlenSubject = new BehaviorSubject<Wahldaten[]>([]);
    public wahlen$ = this.wahlenSubject.asObservable();

    // Observables um in anderen Klassen direkt auf die gewünschten Informationen zugreifen zu können
    benutzerInfo$: Observable<MandantClient.InitialisierungBenutzerData> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.benutzer),
        distinctUntilChanged()
    );
    mandantInfo$: Observable<MandantClient.InitialisierungMandantData> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.mandant),
        distinctUntilChanged()
    );
    mandantDarstellung$: Observable<MandantClient.DarstellungData> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.mandant?.darstellung),
        distinctUntilChanged()
    );
    mandantwechselItems$: Observable<MandantClient.InitialisierungMandantData[]> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.mandantenwechsel),
        distinctUntilChanged()
    );
    benutzerEinstellungen$: Observable<KeyValuePair> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.benutzer?.einstellungen),
        distinctUntilChanged()
    );
    mandantenEinstellungen$: Observable<KeyValuePair> = this.uiInitState$.pipe(
        map((uiInitstate) => uiInitstate?.mandant?.einstellungen),
        distinctUntilChanged()
    );

    /**
     * Liefert true zurück, wenn sowohl die UI-Initialisierung
     * und auch die Benutzerrechte geladen wurden.
     */
    public userInformationLoaded$: Observable<boolean> = combineLatest([this.uiInitState$, this.userRechte$]).pipe(
        map((values) => values.every((b) => b))
    );

    /**
     * Konstruktor
     * @param mandantenService MandantenService
     * @param httpClient HttpClient
     * @param configService ConfigAssetLoaderService
     * @param navService NavigationService
     * @param alertService AlertService
     * @param navigationService NavigationService
     */
    constructor(
        private mandantenService: MandantenService,
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService,
        private navService: NavigationService,
        private alertService: AlertService,
        private navigationService: NavigationService,
        private configAssetLoaderService: ConfigAssetLoaderService,
        private schnellsucheService: SchnellsucheService,
        private capabilitiesService: CapabilitiesService
    ) {
        const uiInfo: MandantClient.CivisInitialisierung = this.getUiInformationFromStorage();
        const uiRechte: string[] = this.getUserRightsFromStorage();
        if (uiInfo) {
            this.updateUiInitialisation(uiInfo);
        }

        if (uiRechte) {
            this.updateUserRights(uiRechte);
        }
    }

    /**
     * Gibt den Namen eines Mandanten zurück
     */
    public getMandantName(): string {
        if (this.uiInitSubject.value) {
            return this.uiInitSubject.value.mandant.name;
        }
        return '';
    }

    /**
     * Update des Observables mit den aktuellen UI Informationen und
     * Aufruf der lokalen Sicherung der Daten im Session Storage.
     * @param uiInfo Aktuelle Informationen für das UI
     */
    updateUiInitialisation(uiInfo: MandantClient.CivisInitialisierung): void {
        this.uiInitSubject.next(uiInfo);
        this.saveUiInformationToStorage(uiInfo);
    }

    /**
     * Aktualisiert die Darstellung eines Mandanten
     * @param mandantId Id des Mandanten
     * @param mandantDarstellung Neue Darstellung des Mandanten
     *
     */
    updateUiMandantDarstellung(
        mandantId: string,
        mandantDarstellung: MandantClient.UIInitialisierungMandantDarstellung
    ): void {
        const uiInfo = this.uiInitSubject.value;
        const uiInfoMandantWechselItem = uiInfo.mandantenwechsel.find(
            (mandant: MandantClient.InitialisierungMandantData) => mandant.id === mandantId
        );
        // Darstellung des Mandantwechselitems aktualisieren
        if (uiInfoMandantWechselItem) {
            uiInfoMandantWechselItem.darstellung = mandantDarstellung;
        }
        // Darstellung des Mandanten aktualisieren
        if (mandantId === this.getMandantId()) {
            uiInfo.mandant.darstellung = mandantDarstellung;
        }
        this.uiInitSubject.next(uiInfo);
    }

    /**
     * Prüft, ob ein Feature Flag aktiv ist
     * @param featureFlagName Name des Feature flags
     * @returns true oder false
     */
    hasFeatureFlag(featureFlagName: string): boolean {
        if (!featureFlagName || !this.uiInitSubject.value?.featureFlags) {
            return false;
        }
        return this.uiInitSubject.value.featureFlags[featureFlagName] ?? false;
    }

    /**
     * Lädt und aktualisiert die Benutzer- und Mandanteninformationen aus dem MandantenService.
     * Lädt und aktualisiert die Rechte des Benutzers aus dem Mandantenservice
     * Wird die Id eines Mandanten als Parameter angegeben wird dieser im Header des Http-Request
     * mitgesendet und ein Mandantenwechsel durchgeführt.
     * @param mandantId Id des ausgewählten Mandanten
     */
    refreshUserAndUiInformation(mandantId: string = ''): Observable<string> {
        const apiCall: Observable<MandantClient.CivisInitialisierung> = mandantId
            ? this.mandantenService.loadUiInitialisierungForMandant(mandantId)
            : this.mandantenService.loadUiIninitialisierung();

        return apiCall.pipe(
            switchMap((response: MandantClient.CivisInitialisierung) => {
                this.updateUiInitialisation(response);
                this.updateUserRights(response.rechte.rechtKeys);

                // Zurücksetzen der Schnellsuche nachdem der Mandant gewechselt wurde
                this.schnellsucheService.emitClearSearchInput(false);

                return this.capabilitiesService.getGeneralCapabilities().pipe(
                    catchError(() => {
                        return of(null);
                    })
                );
            }),
            switchMap((data: GeneralCapabilities | null): Observable<Wahldaten[]> => {
                if (data) {
                    this.capabilitiesService.setCapabilities(data);
                }

                const navItems: NavItem[] = NAV_ITEMS;
                let isKontaktmanagementLizensiert: boolean;

                if (data.menue.hasKontaktmanagement) {
                    const index = navItems.findIndex(
                        (item: NavItem) => item.routerLink === RouterLinkConstants.KONTAKTMANAGEMENT
                    );
                    navItems[index].label = UiConstants.KONTAKTE_LABEL;
                    isKontaktmanagementLizensiert = true;
                } else if (data.menue.hasPersonenverwaltung) {
                    const index = navItems.findIndex(
                        (item: NavItem) => item.routerLink === RouterLinkConstants.KONTAKTMANAGEMENT
                    );
                    navItems[index].label = UiConstants.PERSONEN_LABEL;
                    isKontaktmanagementLizensiert = false;
                }

                const gruppenItem = new NavItem(
                    UiConstants.GRUPPEN_LABEL,
                    UiConstants.GRUPPEN_ICON,
                    [],
                    RouterLinkConstants.GRUPPEN,
                    null,
                    [],
                    2,
                    UiConstants.POSITION_CENTER,
                    false,
                    UiConstants.GRUPPEN_ID
                );

                const clearingItem = new NavItem(
                    UiConstants.KONTAKTE_CLEARING_LABEL,
                    UiConstants.KONTAKTE_CLEARING_ICON,
                    [UiConstants.PERSON_CLEARING],
                    RouterLinkConstants.KONTAKTE_CLEARING,
                    null,
                    [],
                    2,
                    UiConstants.POSITION_CENTER,
                    false,
                    UiConstants.KONTAKTE_CLEARING_ID
                );

                const kmNavItem: NavItem | undefined = navItems.find(
                    (item) => item.routerLink === RouterLinkConstants.KONTAKTMANAGEMENT
                );
                const kmNavItemIndex: number | undefined = navItems.findIndex(
                    (item) => item.routerLink === RouterLinkConstants.KONTAKTMANAGEMENT
                );
                const gruppenNavItemIndex: number | undefined = kmNavItem?.children.findIndex(
                    (item) => item.routerLink === RouterLinkConstants.GRUPPEN
                );
                const clearingNavItemIndex: number | undefined = kmNavItem?.children.findIndex(
                    (item) => item.routerLink === RouterLinkConstants.KONTAKTE_CLEARING
                );
                // Children Nav Elemente des Kontaktmanagements
                const kmNavItemChildren = navItems[kmNavItemIndex].children;

                // Gruppen hinzufügen, wenn Kontaktmanagement lizensiert ist und Gruppen noch nicht vorhanden sind
                if ((!gruppenNavItemIndex || gruppenNavItemIndex === -1) && isKontaktmanagementLizensiert) {
                    kmNavItemChildren.splice(1, 0, gruppenItem);
                }
                // Gruppen entfernen, wenn Kontaktmanagement nicht lizensiert ist und Gruppen vorhanden sind
                else if (gruppenNavItemIndex && !isKontaktmanagementLizensiert) {
                    kmNavItemChildren.splice(gruppenNavItemIndex, gruppenNavItemIndex);
                }
                // Clearing fürs Kontaktmanagement und Personenverwaltung hinzufügen, wenn noch nicht vorhanden ist
                // Feautre Flag für das Clearing auslesen
                if (!clearingNavItemIndex || clearingNavItemIndex === -1) {
                    // Index an dem das Clearing Item eingefügt werden soll
                    const indexToInsert = kmNavItemChildren.length > 0 ? kmNavItemChildren.length + 1 : 0;
                    // Clearing Item hinzufügen
                    kmNavItemChildren.splice(indexToInsert, 0, clearingItem);
                }
                // Setzt die K5App NavItems
                this.setk5AppNavItems(navItems);

                // Wahlen für das Navigationsmenü laden
                if (this.hasPermissions([UiConstants.WAHL_READ])) {
                    return this.httpClient
                        .get<Wahldaten[]>(`${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl`)
                        .pipe(
                            catchError(() => {
                                this.alertService.error('Fehler bei der Verbindung zum Wahlservice!');
                                return of([]);
                            })
                        );
                }
                return of([]);
            }),
            map((wahlen: Wahldaten[]): string => {
                this.wahlenSubject.next(wahlen);
                this.addNavItemsForWahlen(wahlen);
                return 'Erfolgreich initialisiert';
            }),
            catchError((error: HttpErrorResponse) => {
                this.addNavItemsForWahlen([]);
                return throwError(() => error);
            })
        );
    }

    /**
     * Setzt die K5App NavItems und eint mit den andere NavItems
     * @param navItems
     */
    setk5AppNavItems(navItems: NavItem[]): void {
        const wahltagUrl: string = this.configAssetLoaderService.getConfig().k5ExternalApps.wahltagUrl;
        const verfahrenUrl: string = this.configAssetLoaderService.getConfig().k5ExternalApps.verfahrenUrl;
        const wahltagUndVerfahren: NavItem[] = [
            new NavItem(
                UiConstants.WAHLTAG_LABEL,
                UiConstants.WAHLTAG_ICON,
                [UiConstants.WAHLTAG_READ],
                null,
                wahltagUrl,
                [],
                1,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.WAHLTAG_ID
            ),
            new NavItem(
                UiConstants.VERFAHREN_LABEL,
                UiConstants.VERFAHREN_ICON,
                [UiConstants.VERFAHREN_READ],
                null,
                verfahrenUrl,
                [],
                1,
                UiConstants.POSITION_CENTER,
                false,
                UiConstants.VERFAHREN_ID
            )
        ];
        navItems = navItems.concat(wahltagUndVerfahren);
        this.navigationService.setNavItems(navItems);
    }

    /**
     * Baut das Hauptmenü entsprechend der aktuell aktiven Wahlen auf
     * @param wahlen Alle Wahlen
     */
    addNavItemsForWahlen(wahlen: Wahldaten[]): void {
        const navItems: NavItem[] = [];

        // Nach aktiven Wahlen filtern und für jede aktive Wahl Navigationsitems hinzufügen
        wahlen.forEach((wahl) => {
            navItems.push(
                new NavItem(
                    wahl.wahlbezeichnungKurz,
                    UiConstants.WAHLEN_ICON,
                    [],
                    `${RouterLinkConstants.WAHL}/${wahl.id}`,
                    null,
                    [
                        new NavItem(
                            UiConstants.MEINE_WAHLKARTE_LABEL,
                            UiConstants.MEINE_WAHLKARTE_ICON,
                            [],
                            `${RouterLinkConstants.WAHL}/${wahl.id}/${PathConstants.MEINE_WAHLKARTE}`,
                            null,
                            [],
                            3,
                            UiConstants.POSITION_CENTER,
                            false,
                            UiConstants.MEINE_WAHLKARTE_ID
                        ),
                        new NavItem(
                            UiConstants.WAHLBESTAND_LABEL,
                            UiConstants.WAHLBESTAND_ICON,
                            [],
                            `${RouterLinkConstants.WAHL}/${wahl.id}/${PathConstants.WAHLBESTAND}`,
                            null,
                            [],
                            3,
                            UiConstants.POSITION_CENTER,
                            false,
                            UiConstants.WAHLBESTAND_ID
                        )
                    ],
                    2,
                    UiConstants.POSITION_CENTER,
                    true,
                    null
                )
            );
        });

        if (this.hasFeatureFlag('showHistWahlen')) {
            navItems.push(WAHL_HISTORISCH_NAV_ITEM);
        }

        navItems.push(WAHL_CONFIGURATION_NAV_ITEM);
        this.navService.replaceNavItemsOfParent(RouterLinkConstants.WAHL_DASHBOARD, navItems);
    }

    /**
     * Sichert die aktuellen UI Informationen im Session Storage
     * @param uiInit UiInitialiserung
     */
    saveUiInformationToStorage(uiInit: MandantClient.CivisInitialisierung) {
        sessionStorage.setItem(UiConstants.UIINFO, JSON.stringify(uiInit));
    }

    /**
     * Lädt die UI Informationen aus dem Session Storage und wandelt diese in ein
     * CivisInitialisierung-Objekt um.
     */
    getUiInformationFromStorage(): MandantClient.CivisInitialisierung {
        return JSON.parse(sessionStorage.getItem(UiConstants.UIINFO));
    }

    /**
     * Aktualisiert die Benutzerrechte
     * @param userRechte Liste der Benutzerrechte
     */
    updateUserRights(userRechte: string[]): void {
        this.userRechteSubject.next(userRechte);
        this.saveUserRightsToStorage(userRechte);
    }

    /**
     * Sichert die UI Rechte des Benutzers im SessionStorage
     * @param userRechte Rechte im UI
     */
    saveUserRightsToStorage(userRechte: string[]): void {
        sessionStorage.setItem(UiConstants.UIRECHTE, JSON.stringify(userRechte));
    }

    /**
     * Lädt die UI Rechte des Benutzers aus dem SessionStorage
     */
    getUserRightsFromStorage(): string[] {
        return JSON.parse(sessionStorage.getItem(UiConstants.UIRECHTE));
    }

    /**
     * Prüft ob der User eines der benötigten Rechte besitzt
     * @param permissions Benötigte Rechte
     */
    hasPermissions(permissions: string[]): boolean {
        if (this.userRechteSubject.value) {
            let allowedToAccess = false;
            if (!permissions || permissions[0] === undefined) {
                allowedToAccess = true;
                return allowedToAccess;
            }
            for (const permission of permissions) {
                if (this.userRechteSubject.value.includes(permission)) {
                    allowedToAccess = true;
                }
            }
            return allowedToAccess;
        }
        return false;
    }

    /**
     * Liefert die technische BenutzerId oder leeren String zurück
     */
    getBenutzerId(): string {
        return this.uiInitSubject.getValue()?.benutzer ? this.uiInitSubject.getValue().benutzer.id : '';
    }

    /**
     * Liefert die SubjectId des Benutzers oder leeren String zurück
     */
    getSubjectId(): string {
        return this.uiInitSubject.getValue()?.benutzer ? this.uiInitSubject.getValue().benutzer.subjectId : '';
    }

    /**
     * Liefert die technische MandantenId oder leeren String zurück
     */
    getMandantId(): string {
        return this.uiInitSubject.getValue()?.mandant ? this.uiInitSubject.getValue().mandant.id : '';
    }

    /**
     * Liefert die Mandantwechsel Items zurück
     */
    getMandantWechselItems(): null | MandantClient.InitialisierungMandantData[] {
        return this.uiInitSubject.getValue()?.mandantenwechsel ? this.uiInitSubject.getValue().mandantenwechsel : null;
    }

    /**
     * Gibt den Mandant mit Referenz der Wechselitems zurück
     * @returns Mandant
     */
    getMandantFromMandantWechselItems(selectedMandantId?: string): MandantClient.InitialisierungMandantData | null {
        const mandantWechselItems = this.getMandantWechselItems();
        const mandantId = selectedMandantId ?? this.getMandantId();
        return mandantWechselItems.find((item) => item.id === mandantId) ?? null;
    }

    /**
     * Liefert den aktuellen Mandanten zurück
     */
    getMandant(): MandantClient.InitialisierungMandantData | null {
        return this.uiInitSubject.getValue()?.mandant ? this.uiInitSubject.getValue().mandant : null;
    }

    /**
     * Liefert die technische StartMandantenId oder leeren String zurück
     */
    getStartMandantId(): string {
        return this.getBenutzerEinstellungen() ? this.getBenutzerEinstellungen()['start-mandant'] : '';
    }

    /**
     * Liefert den Benutzernamen oder einen Standardbenutzer zurück
     */
    getUserName(): string {
        return this.uiInitSubject.getValue()?.benutzer?.vorname
            ? this.uiInitSubject.getValue().benutzer.familienname + ' ' + this.uiInitSubject.getValue().benutzer.vorname
            : 'Benutzer';
    }

    /**
     * Liefert den Vornamen  zurück
     */
    getVorname(): string {
        return this.uiInitSubject.getValue()?.benutzer?.vorname
            ? this.uiInitSubject.getValue().benutzer.vorname
            : 'Benutzer';
    }

    /**
     * Liefert Vorschau Aktiv zurück
     * @returns true oder false
     */
    getVorschauAktiv(): boolean {
        return this.uiInitSubject.getValue()?.mandant?.vorschauAktiv
            ? this.uiInitSubject.getValue().mandant.vorschauAktiv
            : false;
    }

    /**
     * Liefert die Benutzereinstellungen als KeyValuePairs oder null zurück
     */
    getBenutzerEinstellungen(): KeyValuePair {
        return this.uiInitSubject.getValue()?.benutzer?.einstellungen
            ? this.uiInitSubject.getValue().benutzer.einstellungen
            : null;
    }

    /**
     * Liefert die Mandanteneinstellungen als KeyValuePairs oder null zurück
     */
    getMandantenEinstellungen(): KeyValuePair {
        return this.uiInitSubject.getValue()?.mandant?.einstellungen
            ? this.uiInitSubject.getValue().mandant.einstellungen
            : null;
    }

    /**
     * Gibt den Bundeslandschlüssel des aktuellen Mandanten zurück
     */
    getBundeslandKey(): string {
        return this.uiInitSubject.value?.mandant?.bundeslandKey;
    }

    /**
     * Liefert entweder das vom Mandanten oder Benutzer voreingestellte Farbschema für das UI zurück.
     */
    getUiThemeName(): string {
        const benutzerEinstellungen = this.getBenutzerEinstellungen();
        const mandatenEinstellungen = this.getMandantenEinstellungen();
        let theme = '';
        if (mandatenEinstellungen) {
            const value = this.getValueWithKey(mandatenEinstellungen, UiConstants.THEME);
            theme = value || '';
        }
        // Benutzereinstellungen überschreiben Mandanteneinstellungen
        if (benutzerEinstellungen) {
            const value = this.getValueWithKey(benutzerEinstellungen, UiConstants.THEME);
            theme = value || theme;
        }
        return theme;
    }

    /**
     * Aufruf der Schnittstelle im MandantenService für das Hinzufügen oder Ändern von Benutzereinstellungen
     * und aktualisieren der Daten im UI.
     * @param data Neue oder veränderte Benutzereinstellung
     * @param key Schlüssel der Benutzereinstellung
     */
    saveBenutzerEinstellung<T>(data: T, key: string): Observable<void> {
        const einstellung = {
            [key]: JSON.stringify(data)
        };
        const benutzerId = this.getBenutzerId();
        const mandantId = this.getMandantId();

        return this.mandantenService.changeBenutzerEinstellungenMitMandant(benutzerId, mandantId, einstellung);
    }

    /**
     * Sichert die aktuelle Spaltenauswahl des Nutzers in den Benutzereinstellungen.
     * @param columns Aktuelle Spaltenauswahl als string[]
     * @param key Schlüssel der Benutzereinstellung
     */
    saveColumnSettings(columns: string[], key: string): Observable<void> {
        const einstellung = {
            [key]: JSON.stringify(columns)
        };

        return this.changeBenutzerEinstellungen(key, einstellung);
    }

    /**
     * Speichert ein Objekt in den Benutzerseinstellungen
     * @param data Objekt
     * @param key Schlüssel der Benutzereinstellung
     */
    saveBenutzerEinstellungAndUpdateUiInitSubject<T>(data: T, key: string): Observable<void> {
        const einstellung = {
            [key]: JSON.stringify(data)
        };

        return this.changeBenutzerEinstellungen(key, einstellung);
    }

    /**
     * Liefert die Sortierung des Nutzers aus den
     * geladenen Benutzereinstellungen zurück.
     */
    getSortingSettings(key: string): string | null {
        let sorting = null;
        const value = this.getValueWithKey(this.getBenutzerEinstellungen(), key);

        sorting = value || null;

        return sorting;
    }

    /**
     * Sichert die aktuelle Sortierung des Nutzers in den Benutzereinstellungen.
     * @param sorting Aktuelle Sortierung als string
     * @param key Schlüssel der Sortiereinstellung
     */
    saveSortingSettings(sorting: string, key: string): Observable<void> {
        let einstellung: KeyValuePair;
        if (sorting.toLowerCase().includes('asc') || sorting.toLowerCase().includes('desc')) {
            einstellung = {
                [key]: sorting
            };
        } else {
            einstellung = {
                [key]: null
            };
        }

        return this.changeBenutzerEinstellungen(key, einstellung);
    }

    /**
     * Gibt eine Benutzereinstellung zurück
     * @param key Schlüssel der Einstellung
     */
    getBenutzerEinstellung<T>(key: string): T | null {
        const value = this.getValueWithKey(this.getBenutzerEinstellungen(), key);

        return value ? JSON.parse(value) : null;
    }

    /**
     * Speichert eine Dashboard-Konfiguration als JSON in den Benutzereinstellungen
     * @param key Schlüssel der Einstellungen
     * @param dashboardConfig Dashboard Konfiguration
     */
    saveDashboard<T>(key: string, dashboardConfig: T): Observable<void> {
        const einstellung = {
            [key]: JSON.stringify(dashboardConfig)
        };

        return this.changeBenutzerEinstellungen(key, einstellung);
    }

    /**
     * Gibt eine Benutzereinstellung zurück
     * @param key Schlüssel der Einstellung
     */
    getBenutzerEinstellungFromService<T>(key: string): Observable<T | null> {
        const benutzerId = this.getBenutzerId();
        const mandantId = this.getMandantId();

        return this.mandantenService.getBenutzerEinstellung(benutzerId, mandantId, key).pipe(
            map((value: string) => {
                if (value) {
                    return JSON.parse(value);
                }
                return null;
            })
        );
    }

    /**
     * Aufruf der Schnittstelle im MandantenService für das Hinzufügen oder Ändern von Benutzereinstellungen
     * und aktualisieren der Daten im UI.
     * @param einstellung Neue oder veränderte Benutzereinstellung
     * @param key Schlüssel der Benutzereinstellung
     */
    private changeBenutzerEinstellungen(key: string, einstellung: KeyValuePair): Observable<void> {
        const benutzerId = this.getBenutzerId();
        const mandantId = this.getMandantId();

        return this.mandantenService.changeBenutzerEinstellungenMitMandant(benutzerId, mandantId, einstellung).pipe(
            tap(() => {
                const uiInitialisierung = this.uiInitSubject.value;
                uiInitialisierung.benutzer.einstellungen[key] = einstellung[key];
                this.saveUiInformationToStorage(uiInitialisierung);
                this.uiInitSubject.next(uiInitialisierung);
            })
        );
    }

    /**
     * Liefert aus den Benutzer- oder Mandanteneinstellungen den gesuchten Wert
     * mit Hilfe des Schlüsseleintrages oder null zurück.
     * @param einstellungen Benutzer- oder Mandanteneinstellungen
     * @param key Schlüsseleintrag
     */
    private getValueWithKey(einstellungen: KeyValuePair, key: string): string {
        let value = null;

        if (einstellungen) {
            value = einstellungen[key];
        }
        return value || null;
    }

    /**
     * Liefert true, wenn die Benutzerinformationen geladen sind.
     */
    public hasUserInfo(): boolean {
        if (this.userRechteSubject.value && this.uiInitSubject.value) {
            return true;
        }
        return false;
    }
}
