import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AlertService } from '@core/services/alert.service';
import { UserInformationService } from '@core/services/user-information.service';
import { FileFormats } from '@shared/modules/file-upload/file-format';
import { FileUploadModule } from '@shared/modules/file-upload/file-upload.module';
import {
    UploadFilesDialogComponent,
    UploadFilesDialogData
} from '@shared/modules/file-upload/upload-files-dialog/upload-files-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { Subscription } from 'rxjs';
import { DatenmigrationDto, DatenmigrationStatus } from '../../models/datenmigrationDto';
import { DatenmigrationService } from '../../services/datenmigration.service';

@Component({
    selector: 'k5-settings',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, SharedModule, MatButtonModule, MatDialogModule, FileUploadModule],
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {
    private fileUploadDialogSubscription: Subscription | null = null;
    readonly supportedFileFormats = [FileFormats.ZIP_FILE_FORMAT];

    loading = signal<boolean>(true);
    datenmigration = signal<DatenmigrationDto | null>(null);

    DATENMIGRATION_STATUS = DatenmigrationStatus;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly datenmigrationService: DatenmigrationService,
        private readonly userInformationService: UserInformationService,
        private readonly alertService: AlertService
    ) {}

    /**
     * Lädt den aktuellen Datenmigrationsstatus
     */
    ngOnInit(): void {
        this.datenmigrationService.getDatenmigrationStatus(this.userInformationService.getMandantId()).subscribe({
            next: (datenmigration: DatenmigrationDto) => {
                this.datenmigration.set(datenmigration);
                this.loading.set(false);
            },
            error: (error: HttpErrorResponse) => {
                this.loading.set(false);
                this.alertService.errorResponse(error, 'Fehler beim Laden des Datenmigrationsstatus');
            }
        });
    }

    /**
     * Beendet die Subscriptions
     */
    ngOnDestroy(): void {
        this.fileUploadDialogSubscription?.unsubscribe();
    }

    /**
     * Öffnet den Dialog zum Hochladen einer Datei für die LMR-Datenmigration und
     * startet den Import der hochgeladenen Datei.
     */
    openFileUploadDialog(): void {
        const uploadFilesDialogData: UploadFilesDialogData = {
            title: 'LMR-Import starten',
            formDataName: 'migrationFile',
            fileFormats: this.supportedFileFormats
        };

        const uploadDialogRef = this.matDialog.open(UploadFilesDialogComponent, {
            width: '540px',
            data: uploadFilesDialogData
        });

        this.fileUploadDialogSubscription = uploadDialogRef.componentInstance.uploadClick.subscribe(
            (formData: FormData) => {
                this.datenmigrationService
                    .importLmrDaten(this.userInformationService.getMandantId(), formData)
                    .subscribe({
                        next: (event: HttpEvent<DatenmigrationDto>) => {
                            uploadDialogRef.componentInstance.handleHttpEvent(event);
                            if (event.type === HttpEventType.Response) {
                                this.datenmigration.set(event.body);
                                this.alertService.success('Datei wurde erfolgreich importiert');
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            uploadDialogRef.componentInstance.loading = false;
                            this.alertService.errorResponse(error, 'Fehler beim Import der Datei');
                        }
                    });
            }
        );
    }

    /**
     * Startet den Download des Migrationsprotokolls als CSV-Datei
     */
    downloadMigrationsProtokoll(): void {
        this.datenmigrationService.downloadMigrationsProtokoll(this.userInformationService.getMandantId()).subscribe({
            next: (blob: Blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'migrationsprotokoll.csv';
                a.click();
                window.URL.revokeObjectURL(url);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Download des Migrationsprotokolls');
            }
        });
    }

    /**
     * Führt die Datenmigration durch
     */
    migrateData(): void {
        this.datenmigrationService.migrateData(this.userInformationService.getMandantId()).subscribe({
            next: (datenmigration: DatenmigrationDto) => {
                this.datenmigration.set(datenmigration);
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Durchführung der Datenmigration');
            }
        });
    }
}
