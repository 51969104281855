// @ts-strict-ignore
import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Definiert eine Menükomponente als Overlay
 *
 * Verwendungsbeispiel:
    <!-- Trigger für das Overlay -->
    <div class="flex flex-col items-end pr-5">
        <button mat-icon-button cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">
            <mat-icon fontSet="fa-duotone" fontIcon="fa-gear"></mat-icon>
        </button>
    </div>

    <k5-menu [overlayOrigin]="overlayOrigin" heading="Hugo">
        <k5-menu-group heading="Test">
            <k5-menu-item [loading]="false" icon="fa-gear" description="Einstellungen"></k5-menu-item>
            <k5-menu-avatar-item [loading]="false" initials="DB" description="Einstellungen"></k5-menu-avatar-item>
            <k5-menu-image-item [loading]="false" imageUrl="https://accounts-test.cloudfabrik.at/UserProfile/GetUserImage" description="Einstellungen"></k5-menu-image-item>
        </k5-menu-group>
    </k5-menu>
 */
@Component({
    selector: 'k5-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {
    @Input()
    heading: string = '';

    @Input()
    overlayOrigin: CdkOverlayOrigin;

    @Input()
    open$: Observable<boolean> = null;

    isOpen = signal<boolean>(false);

    connectedPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        }
    ];

    ngOnInit(): void {
        this.overlayOrigin?.elementRef?.nativeElement?.addEventListener('click', this.toggleMenu.bind(this));

        if (this.open$) {
            this.open$.subscribe({
                next: (open: boolean) => {
                    this.isOpen.set(open);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.overlayOrigin?.elementRef?.nativeElement?.removeEventListener('click', this.toggleMenu.bind(this));
    }

    /**
     * Toggled das Menü
     */
    toggleMenu(): void {
        this.isOpen.update((previousValue: boolean) => !previousValue);
    }
}
