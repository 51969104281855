<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayOrigin"
    [cdkConnectedOverlayOpen]="isOpen()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayPositions]="connectedPositions"
    (backdropClick)="isOpen.set(false)"
>
    <div class="rounded-[.25rem] bg-white p-4 drop-shadow-md" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <!-- Menu heading -->
        <div class="pb-2 text-lg font-bold" *ngIf="heading">
            {{ heading }}
        </div>
        <ng-content></ng-content>
    </div>
</ng-template>
