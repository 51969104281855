<div class="size-full">
    @if (error()) {
        <div class="flex size-full items-center justify-center">
            <k5-error message="Fehler bei der Abfrage des Einwohners"></k5-error>
        </div>
    } @else if (loading() || !einwohner()) {
        <k5-loading message="Einwohner wird geladen ..."></k5-loading>
    } @else {
        <k5-responsive-title>
            <k5-page-header [header]="header()" icon="fa-user" class="pl-4"> </k5-page-header>
        </k5-responsive-title>

        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Einwohner">
                <div class="p-4">
                    <k5-section-header title="Person/Einwohner" [icon]="'fa-user'">
                        <div class="ml-4 flex place-content-end">
                            ZMR letzte Änderung am
                            {{ einwohner().letzteAenderung | date: 'dd.MM.yyyy HH:mm:ss' }} ({{
                                einwohner().aenderungstext
                            }})
                        </div>
                    </k5-section-header>

                    <!-- Personendaten des Einwohners -->
                    <div class="flex justify-center pt-4">
                        <div class="grid gap-x-4 gap-y-3 2xl:max-w-[1000px] 2xl:grid-cols-2">
                            <!-- ZMR-Zahl -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">ZMR Zahl</div>
                                <div class="font-bold">{{ einwohner().zmrZahl }}</div>
                            </div>

                            <!-- Geburtsdatum -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Geburtsdatum</div>
                                <div class="font-bold">
                                    {{ einwohner().geburtsdatum | date: 'mediumDate' : 'MEZ' : 'de-AT' }}
                                </div>
                            </div>

                            <!-- Familienname -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Familienname</div>
                                <div class="font-bold">{{ einwohner().familienname }}</div>
                            </div>

                            <!-- Vorname -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Vorname</div>
                                <div class="font-bold">{{ einwohner().vorname }}</div>
                            </div>

                            <!-- Titel -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Titel</div>
                                <div class="font-bold">
                                    {{ einwohner().titel }}
                                </div>
                            </div>

                            <!-- Alternativname -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Alternativname</div>
                                <div class="font-bold">{{ einwohner().alternativname }}</div>
                            </div>

                            <!-- Sonstiger Name -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Sonstiger Name</div>
                                <div class="font-bold">{{ einwohner().sonstigerName }}</div>
                            </div>

                            <!-- Zugezogen von -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Zugezogen von</div>
                                <div class="font-bold">{{ einwohner().staatZugezogen }}</div>
                            </div>

                            <!-- Verzogen nach -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Verzogen nach</div>
                                <div class="font-bold">{{ einwohner().staatVerzogen }}</div>
                            </div>

                            <!-- Geburtsstaat -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Geburtsstaat</div>
                                <div class="font-bold">{{ einwohner().geburtsstaat }}</div>
                            </div>

                            <!-- Geburtsbundesland -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Geburtsbundesland</div>
                                <div class="font-bold">{{ einwohner().geburtsbundesland }}</div>
                            </div>

                            <!-- Geburtsort -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Geburtsort</div>
                                <div class="font-bold">{{ einwohner().geburtsort }}</div>
                            </div>

                            <!-- Familienstand -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Familienstand</div>
                                <div class="font-bold">{{ einwohner().familienstand | familienstand }}</div>
                            </div>

                            <!-- Geschlecht -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Geschlecht</div>
                                <div class="font-bold">{{ einwohner().geschlecht }}</div>
                            </div>

                            <!-- Bpk-ZP -->
                            <div class="grid grid-cols-2 gap-x-6">
                                <div class="text-right text-gray-500">Bpk-ZP</div>
                                <div class="font-bold">{{ einwohner().bpkzp }}</div>
                            </div>
                        </div>
                    </div>

                    <k5-section-header title="Wohnsitze/Meldungen" [icon]="'fa-house'"></k5-section-header>

                    <!-- Wohnsitze des Einwohners -->
                    <div class="pt-4">
                        <k5-einwohner-detail-wohnsitze
                            [mandantId]="mandantId()"
                            [einwohnerId]="einwohner().id"
                        ></k5-einwohner-detail-wohnsitze>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Änderungshistorie">
                <div class="text-center">Funktion noch nicht implementiert</div>
            </mat-tab>
        </mat-tab-group>
    }
</div>
