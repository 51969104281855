<div
    id="mib-btn"
    mat-ripple
    [matRippleDisabled]="disabled"
    [matTooltip]="tooltip"
    tabindex="0"
    class="flex max-h-[6.5rem] max-w-[8rem] flex-col items-center rounded-sm p-2"
    [ngClass]="{ 'action-item': !disabled, 'opacity-50': disabled }"
    (click)="handleMenuItemClick($event)"
>
    <ng-content *ngIf="!loading"> </ng-content>

    <div *ngIf="loading">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>

    <div class="w-full flex-auto overflow-hidden text-clip pt-1 text-center text-xs">{{ description }}</div>
</div>
