/**
 * Definition der notwendigen Werte für die Grid-Komponente
 */
export class EinwohnerDetailGridDefinition {
    //#region Spaltenkonstanten

    static readonly EINWOHNER_DETAIL_COLUMN_KEY_WOHNSITZQUALITAET = 'wohnsitzqualitaet';
    static readonly EINWOHNER_DETAIL_COLUMN_TITLE_WOHNSITZQUALITAET = 'Wohnsitzqualität';
    static readonly EINWOHNER_DETAIL_COLUMN_KEY_ADRESSE = 'adresse';
    static readonly EINWOHNER_DETAIL_COLUMN_TITLE_ADRESSE = 'Adresse';
    static readonly EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_VON = 'gemeldetVon';
    static readonly EINWOHNER_DETAIL_COLUMN_TITLE_GEMELDET_VON = 'gem. von';
    static readonly EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_BIS = 'gemeldetBis';
    static readonly EINWOHNER_DETAIL_COLUMN_TITLE_GEMELDET_BIS = 'gem. bis';
    static readonly EINWOHNER_DETAIL_COLUMN_KEY_ADRESSSTATUS = 'adressstatus';
    static readonly EINWOHNER_DETAIL_COLUMN_TITLE_ADRESSSTATUS = 'Adressstatus';

    static readonly EINWOHNER_DETAIL_DEFAULT_COLUMNS: string[] = [
        EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_COLUMN_KEY_WOHNSITZQUALITAET,
        EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_COLUMN_KEY_ADRESSE,
        EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_VON,
        EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_COLUMN_KEY_GEMELDET_BIS,
        EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_COLUMN_KEY_ADRESSSTATUS
    ];

    //#endregion
}
