import { Pipe, type PipeTransform } from '@angular/core';
import { Wohnsitzqualitaet } from '../models/enums/wohnsitzqualitaet';

@Pipe({
    name: 'wohnsitzqualitaetShort',
    standalone: true,
    pure: true
})
export class WohnsitzqualitaetShortPipe implements PipeTransform {
    /**
     * Übersetzt Wohnsitzqualität aus dem Backend in eine textuelle Form
     * @param value Wohnsitzqualität
     */
    transform(value: Wohnsitzqualitaet): string {
        switch (value) {
            case Wohnsitzqualitaet.Hauptwohnsitz:
                return 'H';
            case Wohnsitzqualitaet.Nebenwohnsitz:
                return 'N';
            case Wohnsitzqualitaet.Obdachlos:
                return 'O';
            default:
                return 'N/A';
        }
    }
}
