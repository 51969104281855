/**
 * Namen der Ids in den Pfaden
 */
export class PathIds {
    static readonly WAHL_ID = 'wahlId';
    static readonly WAEHLER_ID = 'waehlerId';
}

/**
 * Namen der Query-Parameter in den Pfaden
 */
export class QueryParamIds {
    static readonly TAB = 'tab';
    static readonly ANTRAGSCODE_ENTERED = 'antragscodeEntered';
}

/**
 * Path constants for routing modules
 */
export class PathConstants {
    static readonly HOME = 'home';

    // Kontaktmanagement
    static readonly KONTAKTMANAGEMENT = 'kontakte';
    static readonly KONTAKT_LISTE = 'suche';
    static readonly GRUPPEN = 'gruppen';
    static readonly KONTAKTE_CLEARING = 'clearing';
    static readonly KONTAKT_PERSON = 'person';
    static readonly KONTAKT_PERSON_ERSTELLUNG = 'personerstellung';
    static readonly KONTAKT_PERSON_VERKNUEPFUNG = 'personverknuepfung';
    static readonly KONTAKT_PERSON_ERSTELLUNG_MANUELL = 'personerstellungmanuell';
    static readonly KONTAKT_UNTERNEHMEN = 'unternehmen';
    static readonly KONTAKT_UNTERNEHMEN_VERKNUEPFUNG = 'unternehmenverknuepfung';
    static readonly KONTAKT_UNTERNEHMEN_ERSTELLUNG = 'unternehmenerstellung';
    static readonly KONTAKT_UNTERNEHMEN_ERSTELLUNG_MANUELL = 'unternehmenerstellungmanuell';

    // Wahlvorbereitung
    static readonly WAHL = 'wahl';
    static readonly WAHL_ID = `:${PathIds.WAHL_ID}`;
    static readonly WAHLBESTAND = 'wahlbestand';
    static readonly MEINE_WAHLKARTE = 'meineWahlkarte';
    static readonly WAEHLER_ID = `:${PathIds.WAEHLER_ID}`;
    static readonly HISTORISCH = 'histWahlen';

    // Wahlvorbereitung-Reports
    static readonly REPORT_PDF = 'report-pdf';
    static readonly WAHLBESTAND_REPORTS = 'reports';
    static readonly WAEHLERVERZEICHNIS_REPORT = 'waehlerverzeichnis';
    static readonly WAHLINFO_REPORT = 'wahlinfo';
    static readonly WAHLKARTEN_REPORT = 'wahlkarten';
    static readonly ETIKETTEN_REPORT = 'etiketten';
    static readonly RSBRSA_ETIKETTEN_REPORT = 'rsbrsa-etiketten';
    static readonly POSTAUFGABELISTE_REPORT = 'postaufgabeliste';
    static readonly HAUSKUNDMACHUNG_REPORT = 'hauskundmachung';
    static readonly VOLKSBEFRAGUNG_REPORT = 'volksbefragung';

    static readonly STATISTIK_CONTORLLER = 'Statistik';

    // Sprengel
    static readonly SPRENGEL = 'sprengel';
    static readonly SPRENGELGRUPPE_ID_KEY = 'sprengelgruppeId';
    static readonly SPRENGELGRUPPEDETAIL = 'sprengelgruppen';
    static readonly SPRENGELGRUPPECREATE = 'sprengelgruppecreate';
    static readonly WAHLSPRENGELREPORT = 'wahlsprengelreport';

    static readonly ZMR_ABGLEICH = 'zmrAbgleich';
    static readonly TAGS = 'tags';

    //Termin
    static readonly TERMIN = 'termine';

    static readonly SETTINGS = 'einstellungen';
    static readonly USERMANAGEMENT = 'benutzer';
    static readonly ROLES_PERMISSIONS = 'rollenRechte';

    static readonly CONFIGURATION = 'konfiguration';
    static readonly ADRESSEN = 'adressen';

    static readonly CHAT = 'chat';
    static readonly USERPROFILE = 'benutzerprofil';

    // Initialisierung
    static readonly INITIALIZATION = 'initialization';

    // Fehler Seite
    static readonly ERROR = 'error';
    static readonly DASHBOARD = 'dashboard';
}

/**
 * Constants for SPA navigation router links
 */
export class RouterLinkConstants {
    static readonly HOME = `/${PathConstants.HOME}`;

    // Kontaktmanagement
    static readonly KONTAKTMANAGEMENT = `/${PathConstants.KONTAKTMANAGEMENT}`;
    static readonly KONTAKTE_LISTE = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}`;
    static readonly GRUPPEN = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.GRUPPEN}`;
    static readonly KONTAKTE_CLEARING = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKTE_CLEARING}`;

    static readonly KONTAKTE_PERSON_DETAIL = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_PERSON}`;
    static readonly KONTAKTE_PERSON_ERSTELLUNG = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_PERSON_ERSTELLUNG}`;
    static readonly KONTAKT_PERSON_VERKNUEPFUNG = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_PERSON_VERKNUEPFUNG}`;
    static readonly KONTAKTE_PERSON_ERSTELLUNG_MANUELL = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_PERSON_ERSTELLUNG_MANUELL}`;

    static readonly KONTAKTE_UNTERNEHMEN_DETAIL = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_UNTERNEHMEN}`;
    static readonly KONTAKTE_UNTERNEHMEN_ERSTELLUNG = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_UNTERNEHMEN_ERSTELLUNG}`;
    static readonly KONTAKT_UNTERNEHMEN_VERKNUEPFUNG = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_UNTERNEHMEN_VERKNUEPFUNG}`;
    static readonly KONTAKTE_UNTERNEHMEN_ERSTELLUNG_MANUELL = `${RouterLinkConstants.KONTAKTMANAGEMENT}/${PathConstants.KONTAKT_LISTE}/${PathConstants.KONTAKT_UNTERNEHMEN_ERSTELLUNG_MANUELL}`;

    // Wahlvorbereitung
    static readonly WAHL = `/${PathConstants.WAHL}`;
    static readonly WAHL_DASHBOARD = `${RouterLinkConstants.WAHL}/${PathConstants.DASHBOARD}`;
    static readonly CONFIGURATION_WAHL = `/${RouterLinkConstants.WAHL}/${PathConstants.CONFIGURATION}`;
    static readonly WAHL_HISTORISCH = `/${PathConstants.WAHL}/${PathConstants.HISTORISCH}`;

    // Konfiguration
    static readonly CONFIGURATION = `/${PathConstants.CONFIGURATION}`;
    static readonly CONFIGURATION_ADRESSEN = `/${RouterLinkConstants.CONFIGURATION}/${PathConstants.ADRESSEN}`;
    static readonly SPRENGEL = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.SPRENGEL}`;
    static readonly ZMR_ABGLEICH = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.ZMR_ABGLEICH}`;
    static readonly TAGS = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.TAGS}`;
    static readonly SPRENGELGRUPPEDETAIL = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.SPRENGEL}/${PathConstants.SPRENGELGRUPPEDETAIL}`;
    static readonly SPRENGELGRUPPEREPORT = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.SPRENGEL}/${PathConstants.SPRENGELGRUPPEDETAIL}/${PathConstants.WAHLSPRENGELREPORT}`;
    static readonly SPRENGELGRUPPECREATE = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.SPRENGEL}/${PathConstants.SPRENGELGRUPPECREATE}`;
    static readonly TERMIN = `${RouterLinkConstants.CONFIGURATION}/${PathConstants.TERMIN}`;
    static readonly TERMIN_ERSTELLEN = `${RouterLinkConstants.TERMIN}/erstellen`;

    static readonly CHAT = `/${PathConstants.CHAT}`;
    static readonly USERPROFILE = `/${PathConstants.USERPROFILE}`;

    // Initialisierung
    static readonly INITIALIZATION = `/${PathConstants.INITIALIZATION}`;

    // Fehler Seite
    static readonly ERROR = `/${PathConstants.ERROR}`;
}

export class UrlConstants {
    // Verfahren
    static readonly VERFAHREN_URL = 'https://verfahren-rc.k5next.at/';
    // Wahltag
    static readonly WAHLTAG_URL = 'https://wahltag-rc.k5next.at/';
}
