import { Pipe, type PipeTransform } from '@angular/core';
import { WohnsitzStatusFilter } from '../models/enums/wohnsitzStatusFilter';

@Pipe({
    name: 'wohnsitzStatusFilter',
    standalone: true,
    pure: true
})
export class WohnsitzStatusFilterPipe implements PipeTransform {
    /**
     * Übersetzt WohnsitzStatusFilter in eine textuelle Form
     * @param value WohnsitzStatusFilter
     */
    transform(value: WohnsitzStatusFilter): string {
        switch (value) {
            case WohnsitzStatusFilter.Aktuell:
                return 'Aktuell';
            case WohnsitzStatusFilter.Historisch:
                return 'Historisch';
            default:
                return 'Ungültig';
        }
    }
}
