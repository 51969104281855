import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, input, signal } from '@angular/core';
import { FilterCategory } from '@shared/models/filterCategory';
import { KATEGORIE_WOHNSITZ_STATUS } from '../../constants/meldewesen.constants';
import { WohnsitzStatusFilter } from '../../models/enums/wohnsitzStatusFilter';
import { WohnsitzStatusFilterPipe } from '../../pipes/wohnsitz-status-filter.pipe';
import { AlertService } from '@core/services/alert.service';
import { FilterOption } from '@shared/models/filterOption';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SharedModule } from '@shared/shared.module';
import { EinwohnerDetailWohnsitzeTableComponent } from '../einwohner-detail-wohnsitze-table/einwohner-detail-wohnsitze-table.component';
import { WohnsitzeService } from '../../services/wohnsitze.service';
import { WohnsitzDetailDto } from '../../models/wohnsitzDetailDto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'k5-einwohner-detail-wohnsitze',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, EinwohnerDetailWohnsitzeTableComponent],
    templateUrl: './einwohner-detail-wohnsitze.component.html'
})
export class EinwohnerDetailWohnsitzeComponent implements OnInit, OnDestroy {
    private readonly subscription: Subscription = new Subscription();
    private readonly wohnsitzStatusFilterSubject = new BehaviorSubject<WohnsitzStatusFilter[]>([]);

    mandantId = input.required<string>();
    einwohnerId = input.required<string>();

    loading = signal<boolean>(true);
    wohnsitze = signal<WohnsitzDetailDto[]>([]);
    wohnsitzStatusFilter$ = this.wohnsitzStatusFilterSubject.asObservable();

    filterCategories: FilterCategory[] = [
        {
            name: KATEGORIE_WOHNSITZ_STATUS,
            filterOptions: [
                {
                    name: this.wohnsitzStatusFilterPipe.transform(WohnsitzStatusFilter.Aktuell),
                    key: WohnsitzStatusFilter.Aktuell,
                    selected: false
                },
                {
                    name: this.wohnsitzStatusFilterPipe.transform(WohnsitzStatusFilter.Historisch),
                    key: WohnsitzStatusFilter.Historisch,
                    selected: false
                }
            ]
        }
    ];

    constructor(
        private readonly wohnsitzStatusFilterPipe: WohnsitzStatusFilterPipe,
        private readonly wohnsitzeService: WohnsitzeService,
        private readonly alertService: AlertService
    ) {}

    ngOnInit(): void {
        // Subscription für die Wohnsitze des Einwohners
        this.subscription.add(
            this.wohnsitzeService.getWohnsitzeOfEinwohner(this.mandantId(), this.einwohnerId(), []).subscribe({
                next: (wohnsitze: WohnsitzDetailDto[]) => {
                    this.wohnsitze.set(wohnsitze);
                    this.loading.set(false);
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorResponse(error, 'Fehler beim Laden der Wohnsitze');
                    this.loading.set(false);
                }
            })
        );

        // Subscription für die ausgewählten Filter
        this.subscription.add(
            this.wohnsitzStatusFilter$.subscribe((selectedWohnsitzStatusFilters: WohnsitzStatusFilter[]) => {
                this.loading.set(true);
                this.wohnsitzeService
                    .getWohnsitzeOfEinwohner(this.mandantId(), this.einwohnerId(), selectedWohnsitzStatusFilters)
                    .subscribe({
                        next: (wohnsitze: WohnsitzDetailDto[]) => {
                            this.wohnsitze.set(wohnsitze);
                            this.loading.set(false);
                        },
                        error: (error: HttpErrorResponse) => {
                            this.alertService.errorResponse(error, 'Fehler beim Laden der Wohnsitze');
                            this.loading.set(false);
                        }
                    });
            })
        );
    }

    /**
     * Aufheben der Subscriptions
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Behandelt Änderung der ausgewählten Filter
     * @param filterCategories Aktueller Zustand der Filter
     */
    handleFilterChange(filterCategories: FilterCategory[]): void {
        const wohnsitzStatusFilterCategory: FilterCategory | undefined = filterCategories.find(
            (category: FilterCategory) => category.name === KATEGORIE_WOHNSITZ_STATUS
        );

        if (wohnsitzStatusFilterCategory === undefined) {
            this.alertService.error('Filterkategorie nicht gefunden');
            return;
        }

        const wohnsitzStatusFilterOptions: FilterOption[] | undefined = wohnsitzStatusFilterCategory.filterOptions;

        if (wohnsitzStatusFilterOptions === undefined) {
            this.alertService.error('Filteroptionen nicht gefunden');
            return;
        }

        const selectedWohnsitzStatusFilters: WohnsitzStatusFilter[] = wohnsitzStatusFilterOptions
            .filter((filterOption: FilterOption) => filterOption.selected)
            .map((filterOption) => filterOption.key as WohnsitzStatusFilter);

        this.wohnsitzStatusFilterSubject.next(selectedWohnsitzStatusFilters);
    }
}
