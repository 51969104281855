// @ts-strict-ignore
import { Component, Input } from '@angular/core';
import { MenuItemBaseComponent } from '../menu-item-base/menu-item-base';

@Component({
    selector: 'k5-menu-item',
    templateUrl: './menu-item.component.html'
})
export class MenuItemComponent extends MenuItemBaseComponent {
    @Input()
    icon: string;
}
