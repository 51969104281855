import { Pipe, type PipeTransform } from '@angular/core';
import { Wohnsitzqualitaet } from '../models/enums/wohnsitzqualitaet';

@Pipe({
    name: 'wohnsitzqualitaet',
    standalone: true,
    pure: true
})
export class WohnsitzqualitaetPipe implements PipeTransform {
    /**
     * Übersetzt Wohnsitzqualität aus dem Backend in eine textuelle Form
     * @param value Wohnsitzqualität
     */
    transform(value: Wohnsitzqualitaet): string {
        switch (value) {
            case Wohnsitzqualitaet.Hauptwohnsitz:
                return 'Hauptwohnsitz';
            case Wohnsitzqualitaet.Nebenwohnsitz:
                return 'Nebenwohnsitz';
            case Wohnsitzqualitaet.Obdachlos:
                return 'Obdachlos';
            default:
                return 'Unbekannt';
        }
    }
}
