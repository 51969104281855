/**
 * Website tab title text
 */
export const MELDEWESEN_TITLE = 'Meldewesen';

/**
 * Permissions
 */
export const MELDEWESEN_READ = 'einwohner-read';
export const MELDEWESEN_PROCESS = 'einwohner-process';

/**
 * Navigation icons
 */
export const MELDEWESEN_ICON = 'house-person-return';
export const MELDEWESEN_EINWOHNER_ICON = 'people-roof';
export const MELDEWESEN_SETTINGS_ICON = 'gear';

/**
 * Navigation labels
 */
export const MELDEWESEN_LABEL = 'Meldewesen';
export const MELDEWESEN_EINWOHNER_LABEL = 'Einwohner';
export const MELDEWESEN_SETTINGS_LABEL = 'Einstellungen';

/**
 * Navigation item ids
 */
export const MELDEWESEN_DASHBOARD_ID = 'nav-content-k5-nav-link-meldewesen-dashboard';
export const MELDEWESEN_EINWOHNER_ID = 'nav-content-k5-nav-link-meldewesen-einwohner';
export const MELDEWESEN_SETTINGS_ID = 'nav-content-k5-nav-link-meldewesen-settings';

/**
 * URL-Parts
 */
export const MELDEWESEN_DASHBOARD = 'dashboard';
export const MELDEWESEN_EINWOHNER = 'einwohner';
export const MELDEWESEN_SETTINGS = 'settings';

/**
 * Path Ids
 */
export const EINWOHNER_ID = 'einwohnerId';

/**
 * Realtive Paths
 */
export const MELDEWESEN_ROOT = 'meldewesen';
export const MELDEWESEN_DASHBOARD_PATH = `/${MELDEWESEN_ROOT}/${MELDEWESEN_DASHBOARD}`;
export const MELDEWESEN_EINWOHNER_PATH = `/${MELDEWESEN_ROOT}/${MELDEWESEN_EINWOHNER}`;
export const MELDEWESEN_SETTINGS_PATH = `/${MELDEWESEN_ROOT}/${MELDEWESEN_SETTINGS}`;

/**
 * Detailsuche Kriterien
 * @remarks Änderungen müssen auch im Backend beachtet werden
 */
export const zmrZahl: string = 'zmrZahl';
export const bpkzp: string = 'bpkzp';
export const name: string = 'name';
export const geburtsdatum: string = 'geburtsdatum';
export const wohnsitzqualitaet: string = 'wohnsitzqualitaet';
export const meldeadresse: string = 'meldeadresse';

/**
 * Filterkategorien
 */
export const KATEGORIE_WOHNSITZ_STATUS = 'Status';
