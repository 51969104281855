import { Component, Input } from '@angular/core';

@Component({
    selector: 'k5-menu-group',
    templateUrl: './menu-group.component.html'
})
export class MenuGroupComponent {
    @Input()
    heading = '';
}
