import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { K5NextDataSource, TableComponent } from '@k5next/ui-components';
import { CommonModule } from '@angular/common';
import { WohnsitzDetailDto } from '../../models/wohnsitzDetailDto';
import { EinwohnerDetailGridDefinition } from './einwohner-detail-grid-definition';
import { MatTableModule } from '@angular/material/table';
import { WohnsitzqualitaetPipe } from '../../pipes/wohnsitzqualitaet.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertService } from '@core/services/alert.service';

@Component({
    selector: 'k5-einwohner-detail-wohnsitze-table',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MatTableModule, TableComponent, WohnsitzqualitaetPipe, MatTooltipModule, MatButtonModule],
    templateUrl: './einwohner-detail-wohnsitze-table.component.html'
})
export class EinwohnerDetailWohnsitzeTableComponent {
    wohnsitze = input.required<WohnsitzDetailDto[]>();
    dataSource = computed(() => new K5NextDataSource<WohnsitzDetailDto>(this.wohnsitze()));

    EINWOHNER_DETAIL_TABLE_CONSTANTS = EinwohnerDetailGridDefinition;
    columns: string[] = EinwohnerDetailGridDefinition.EINWOHNER_DETAIL_DEFAULT_COLUMNS;

    constructor(private readonly alertService: AlertService) {}

    /**
     * Gibt die Id des MeldungEntity zurück, um dieses in der Liste eindeutig zu identifizieren
     * @param item Wohnsitz in der Tabelle
     * @returns Id des MeldungEntity
     */
    trackByFn(item: WohnsitzDetailDto): string {
        return item.id;
    }

    /**
     * Triggert Wohnsitz abmelden für den ausgewählten Wohnsitz
     * @param wohnsitz WohnsitzDetailDto
     * @remarks Funktion noch nicht implementiert
     */
    wohnsitzAbmelden(wohnsitz: WohnsitzDetailDto): void {
        console.log(wohnsitz);
        this.alertService.info('Funktion noch nicht implementiert.');
    }

    /**
     * Triggert Wohnsitz ummelden für den ausgewählten Wohnsitz
     * @param wohnsitz WohnsitzDetailDto
     * @remarks Funktion noch nicht implementiert
     */
    wohnsitzUmmelden(wohnsitz: WohnsitzDetailDto): void {
        console.log(wohnsitz);
        this.alertService.info('Funktion noch nicht implementiert.');
    }
}
