import { signal } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, filter } from 'rxjs';

/**
 * Interface für den Zustand einer Tabelle
 */
export interface TableState {
    pageNumber: number;
    sort: Sort;
    schnellsucheQuery: string | null;
    suchkriterien: { [key: string]: string } | null;
}

/**
 * Interface für den Zustand der Selektion einer Tabelle
 */
export interface SelectionState<T> {
    selection: T[];
    invertedSelection: T[];
    invertedSelectionModeActive: boolean;
}

/**
 * State-Service für das Zusammenspiel von Tabelle, Schnellsuche und Detailsuche
 * @remarks Für jeden Einsatz muss ein eigener Injection Token definiert werden
 */
export class TableStateService<T> {
    private readonly _pageSize = 200;
    private readonly _defaultColumns: string[];
    private readonly _defaultSorting: Sort;

    /**
     * Der aktuelle Zustand der Tabelle
     * @remarks Wird als BehaviorSubject gehalten, um bei Änderungen die Komponenten zu benachrichtigen
     */
    private readonly stateSubject: BehaviorSubject<TableState>;

    /**
     * Der aktuelle Zustand der Tabelle als Observable
     * @remarks Wird von der Tabelle genutzt, um auf Änderungen zu reagieren und Daten zu laden
     */
    state$: Observable<TableState>;

    /**
     * Der aktuelle Zustand der Selektion der Tabelle
     * - selection: Selektierte Zeilen
     * - invertedSelection: Nicht selektierte Zeilen
     * - invertedSelectionModeActive: Gibt an, ob der invertierte Selektionsmodus aktiv ist
     */
    private readonly selectionStateSubject = new BehaviorSubject<SelectionState<T>>({
        selection: [],
        invertedSelection: [],
        invertedSelectionModeActive: false
    });

    /**
     * Der aktuelle Zustand der Selektion der Tabelle als Observable
     */
    selectionState$: Observable<SelectionState<T>> = this.selectionStateSubject.asObservable();

    /**
     * Die aktuell angezeigten Spalten der Tabelle
     */
    private readonly displayedColumnsSubject = new BehaviorSubject<string[] | null>(null);

    /**
     * Die aktuell angezeigten Spalten der Tabelle als Observable
     * @remarks Wird von der Tabelle genutzt, um auf Änderungen zu reagieren und die Spalten zu aktualisieren
     */
    displayedColumns$: Observable<string[]> = this.displayedColumnsSubject
        .asObservable()
        .pipe(filter((columns: string[] | null): columns is string[] => !!columns));

    /**
     * Die Anzahl der insgesamt vorhandenen Zeilen
     */
    totalRows = signal(0);

    /**
     * Die Anzahl der ausgewählten Zeilen
     */
    selectedRows = signal(0);

    /**
     * Die Anzahl der geladenen Zeilen
     */
    loadedRows = signal(0);

    /**
     * Gibt die Anzahl der Zeilen zurück, die auf einer Seite angezeigt werden
     */
    get pageSize(): number {
        return this._pageSize;
    }

    /**
     * Gibt die aktuell angezeigten Spalten der Tabelle zurück
     */
    get displayedColumns(): string[] {
        return this.displayedColumnsSubject.value ?? this._defaultColumns;
    }

    /**
     * Gibt den aktuellen Zustand der Tabelle zurück
     */
    get tableState(): TableState {
        return this.stateSubject.value;
    }

    /**
     * Gibt den aktuellen Zustand der Selektion der Tabelle zurück
     */
    get selectionState(): SelectionState<T> {
        return this.selectionStateSubject.value;
    }

    /**
     * Konstruktor
     * @param defaultColumns Standardmäßig anzuzeigende Spalten der Tabelle
     * @param defaultSorting Standardmäßige Sortierung der Tabelle
     * @remarks Müssen für jede Tabelle in einem eigenen Injection Token definiert werden
     */
    constructor(defaultColumns: string[], defaultSorting: Sort) {
        this._defaultColumns = defaultColumns;
        this._defaultSorting = defaultSorting;

        this.stateSubject = new BehaviorSubject<TableState>({
            pageNumber: 0,
            sort: this._defaultSorting,
            schnellsucheQuery: null,
            suchkriterien: null
        });

        this.state$ = this.stateSubject.asObservable();
    }

    /**
     * Setzt den Zustand der Tabelle auf die Standardwerte zurück
     */
    resetState(): void {
        this.stateSubject.next({
            pageNumber: 0,
            sort: this._defaultSorting,
            schnellsucheQuery: null,
            suchkriterien: null
        });
    }

    /**
     * Setzt den Zustand der Tabelle auf die erste Seite zurück
     */
    resetPage(): void {
        this.stateSubject.next({
            ...this.stateSubject.value,
            pageNumber: 0
        });
    }

    /**
     * Aktualisiert den Zustand der Tabelle
     * @param page Zu ladende Seite
     * @param sort Sortierung der Tabelle
     * @param schnellsucheQuery Query der Schnellsuche
     * @param suchkriterien Suchkriterien der Detailsuche
     */
    updateState(
        page: number,
        sort: Sort,
        schnellsucheQuery: string | null,
        suchkriterien: { [key: string]: string } | null
    ): void {
        this.stateSubject.next({
            pageNumber: page,
            sort,
            schnellsucheQuery,
            suchkriterien
        });
    }

    /**
     * Aktualisiert die Sortierung der Tabelle
     * @param sort Sortierung der Tabelle
     */
    updateSort(sort: Sort): void {
        this.stateSubject.next({
            ...this.stateSubject.value,
            sort,
            pageNumber: 0
        });
    }

    /**
     * Lädt die nächste Seite der Tabelle, wenn weitere Zeilen vorhanden sind
     */
    loadNextPage(): void {
        // Ist das Ende der Liste erreicht, wird keine weitere Seite geladen
        if (this.totalRows() <= (this.stateSubject.value.pageNumber + 1) * this.pageSize) {
            return;
        }

        this.stateSubject.next({
            ...this.stateSubject.value,
            pageNumber: this.stateSubject.value.pageNumber + 1
        });
    }

    /**
     * Aktualisiert den aktuellen Zustand der Selektion der Tabelle
     * @param selection Selektierte geladene Zeilen
     * @param invertedSelection Nicht selektierte Zeilen
     * @param invertedSelectionModeActive Gibt an, ob der invertierte Selektionsmodus aktiv ist
     * @description Wurden alle Zeilen selektiert, wird der Selektionsmodus für die nicht selektierten Zeilen aktiviert.
     * So kann die Auswahl einzelner Zeilen aufgehoben werden, ohne die gesamten Daten vom Backend abzufragen.
     * Der Modus wird deaktiviert, wenn alle Zeilen mit dem Haupt-Toggle abgewählt werden.
     * Damit ist es möglich alle Datensätze außer den nachträglich deselektierten Zeilen an das Backend
     * zur weiteren Verabeitung zu senden.
     */
    updateSelectionState(selection: T[], invertedSelection: T[], invertedSelectionModeActive: boolean): void {
        if (invertedSelectionModeActive) {
            this.selectedRows.set(this.totalRows() - invertedSelection.length);
        } else {
            this.selectedRows.set(selection.length);
        }

        this.selectionStateSubject.next({
            selection,
            invertedSelection,
            invertedSelectionModeActive
        });
    }

    /**
     * Aktualisiert die angezeigten Spalten der Tabelle
     * @param displayedColumns anzuzeigenden Spalten
     */
    updateDisplayedColumns(displayedColumns: string[]): void {
        this.displayedColumnsSubject.next(displayedColumns);
    }
}
